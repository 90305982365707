<template>
  <b-card title="ECC" sub-title="Information about ECC">
    <b-row>
      <b-col md="8">
        <b-form-group
          label="Temperature [celsius]"
          label-for="intraop_ECC_ECC_temperature"
          label-cols-md="4"
        >
          <b-form-input
            v-model="patientInfo.intraop_ECC_ECC_temperature"
            id="intraop_ECC_ECC_temperature"
            type="number"
            placeholder="Temperature [celsius]"
            :readonly="readOnly"
          />
        </b-form-group>
      </b-col>
      <b-col md="8">
        <b-form-group
          label="Minutes"
          label-for="intraop_ECC_ECC_minutes"
          label-cols-md="4"
        >
          <b-form-input
            v-model="patientInfo.intraop_ECC_ECC_minutes"
            id="intraop_ECC_ECC_minutes"
            type="number"
            placeholder="Minutes"
            :readonly="readOnly"
          />
        </b-form-group>
      </b-col>
      <b-col md="8">
        <b-form-group
          label="Cross clamp [min.]"
          label-for="intraop_ECC_cross_clamp_time_minutes"
          label-cols-md="4"
        >
          <b-form-input
            v-model="patientInfo.intraop_ECC_cross_clamp_time_minutes"
            id="intraop_ECC_cross_clamp_time_minutes"
            type="number"
            placeholder="Cross clamp [min.]"
            :readonly="readOnly"
          />
        </b-form-group>
      </b-col>
      <b-col md="8">
        <b-form-group
          label="Circulatory arrest [min.]"
          label-for="intraop_ECC_circulatory_arrest_minutes"
          label-cols-md="4"
        >
          <b-form-input
            v-model="patientInfo.intraop_ECC_circulatory_arrest_minutes"
            id="intraop_ECC_circulatory_arrest_minutes"
            type="number"
            placeholder="Circulatory arrest [min.]"
            :readonly="readOnly"
          />
        </b-form-group>
      </b-col>
      <b-col md="8">
        <b-form-group
          label="Reperfusion [min.]"
          label-for="intraop_ECC_reperfusion_time_minutes"
          label-cols-md="4"
        >
          <b-form-input
            v-model="patientInfo.intraop_ECC_reperfusion_time_minutes"
            id="intraop_ECC_reperfusion_time_minutes"
            type="number"
            placeholder="Reperfusion [min.]"
            :readonly="readOnly"
          />
        </b-form-group>
      </b-col>
      <b-col md="8">
        <b-form-group
          label="Cardioplegia"
          label-for="intraop_ECC_cardioplegia"
          label-cols-md="4"
        >
          <v-select
            v-model="patientInfo.intraop_ECC_cardioplegia"
            label="value"
            :options="options"
            :disabled="readOnly"
          />
        </b-form-group>
      </b-col>
      <b-col md="8">
        <b-form-group
          label="Cardioplegia Type"
          label-for="intraop_ECC_cardioplegia_type"
          label-cols-md="4"
        >
          <v-select
            v-model="patientInfo.intraop_ECC_cardioplegia_type"
            label="value"
            :options="options1"
            :disabled="readOnly"
          />
        </b-form-group>
      </b-col>
      <b-col md="8">
        <b-form-group
          label="Cellsave"
          label-for="intraop_ECC_cellsaver"
          label-cols-md="4"
        >
          <v-select
            v-model="patientInfo.intraop_ECC_cellsaver"
            label="value"
            :options="options"
            :disabled="readOnly"
          />
        </b-form-group>
      </b-col>
      <b-col md="8">
        <b-form-group
          label="Hemofiltration on ECC"
          label-for="intraop_ECC_hemofiltration_on_ECC"
          label-cols-md="4"
        >
          <v-select
            v-model="patientInfo.intraop_ECC_hemofiltration_on_ECC"
            label="value"
            :options="options"
            :disabled="readOnly"
          />
        </b-form-group>
      </b-col>
      <b-col md="8">
        <b-form-group
          label="Hemodynamic status end"
          label-for="intraop_ECC_hemodynamic_status_end"
          label-cols-md="4"
        >
          <v-select
            v-model="patientInfo.intraop_ECC_hemodynamic_status_end"
            label="value"
            :options="options2"
            :disabled="readOnly"
          />
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-form-group
          label="ECC Duration First Aortic Cross Clamp [min]"
          label-for="ecc_duration_first_aortic_cross_clamp"
          label-cols-md="4"
        >
          <b-form-input
            v-model="patientInfo.intraop_ecc_duration_first_aortic_cross_clamp"
            id="ecc_duration_first_aortic_cross_clamp"
            type="number"
            placeholder="ECC Duration First Aortic Cross Clamp"
            :readonly="readOnly"
          />
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-form-group
          label="ECC Duration First Cardiopulmonary Bypass"
          label-for="ecc_duration_first_cardiopulmonary_bypass"
          label-cols-md="4"
        >
          <b-form-input
            v-model="
              patientInfo.intraop_ecc_duration_first_cardiopulmonary_bypass
            "
            id="ecc_duration_first_cardiopulmonary_bypass"
            type="number"
            placeholder="ECC Duration First Cardiopulmonary Bypass"
            :readonly="readOnly"
          />
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-form-group
          label="More Than One Clamp Session Needed"
          label-for="more_than_one_clamp_session"
          label-cols-md="4"
        >
          <v-select
            v-model="patientInfo.intraop_ecc_more_than_one_clamp_session_needed"
            label="value"
            :options="options"
            :disabled="readOnly"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BFormDatepicker,
  BFormTextarea,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { dateMask } from "@core/utils/utils";
import { mapState } from "vuex";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BFormDatepicker,
    BFormTextarea,
    vSelect,
  },
  data() {
    return {
      patientInfo: {
        _id: null,
        intraop_ECC_ECC_temperature: 0,
        intraop_ECC_ECC_minutes: 0,
        intraop_ECC_cross_clamp_time_minutes: 0,
        intraop_ECC_circulatory_arrest_minutes: 0,
        intraop_ECC_reperfusion_time_minutes: 0,
        intraop_ECC_cardioplegia: "",
        intraop_ECC_cardioplegia_type: "",
        intraop_ECC_cellsaver: "",
        intraop_ECC_hemofiltration_on_ECC: "",
        intraop_ECC_hemodynamic_status_end: "",
        intraop_ecc_duration_first_aortic_cross_clamp: "",
        intraop_ecc_duration_first_cardiopulmonary_bypass: "",

        intraop_ecc_more_than_one_clamp_session_needed: "",
      },
      options: ["yes", "no"],
      options1: ["Calafiori", "Brettschneider"],
      options2: [
        "stable without inotropes",
        "stable with low inotropes",
        "stable with high inotropes",
        "LOS",
        "circulatory support",
      ],
    };
  },
  computed: {
    ...mapState("patient", ["patient", "role"]),
    readOnly() {
      return this.role == "viewer" || this.role == "";
    },
  },
  watch: {
    patient(v, ov) {
      if (v._id) {
        this.setPatientInfo(v);
      }
    },
  },
  mounted() {
    if (this.patient._id) {
      this.setPatientInfo(this.patient);
    }
  },
  methods: {
    dateMask,
    setPatientInfo(v) {
      this.patientInfo._id = v._id;

      this.patientInfo.intraop_ECC_ECC_temperature =
        v.intraop_ECC_ECC_temperature;
      this.patientInfo.intraop_ECC_ECC_minutes = v.intraop_ECC_ECC_minutes;
      this.patientInfo.intraop_ECC_cross_clamp_time_minutes =
        v.intraop_ECC_cross_clamp_time_minutes;
      this.patientInfo.intraop_ECC_circulatory_arrest_minutes =
        v.intraop_ECC_circulatory_arrest_minutes;
      this.patientInfo.intraop_ECC_reperfusion_time_minutes =
        v.intraop_ECC_reperfusion_time_minutes;
      this.patientInfo.intraop_ECC_cardioplegia = v.intraop_ECC_cardioplegia;
      this.patientInfo.intraop_ECC_cardioplegia_type =
        v.intraop_ECC_cardioplegia_type;
      this.patientInfo.intraop_ECC_cellsaver = v.intraop_ECC_cellsaver;
      this.patientInfo.intraop_ECC_hemofiltration_on_ECC =
        v.intraop_ECC_hemofiltration_on_ECC;
      this.patientInfo.intraop_ECC_hemodynamic_status_end =
        v.intraop_ECC_hemodynamic_status_end;
      this.patientInfo.intraop_ecc_duration_first_aortic_cross_clamp =
        v.intraop_ecc_duration_first_aortic_cross_clamp;
      this.patientInfo.intraop_ecc_duration_first_cardiopulmonary_bypass =
        v.intraop_ecc_duration_first_cardiopulmonary_bypass;

      this.patientInfo.intraop_ecc_more_than_one_clamp_session_needed =
        v.intraop_ecc_more_than_one_clamp_session_needed;
    },
  },
};
</script>

<style>
</style>
