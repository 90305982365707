<template>
  <b-card title="Operation" sub-title="Information about operation">
    <b-row>
      <b-col md="6">
        <b-row>
          <b-col cols="12">
            <b-card class="bordered-card" title="Times">
              <b-row>
                <b-col md="12">
                  <b-form-group
                    label="OP start"
                    label-for="op_start_date"
                    label-cols-md="4"
                  >
                    <flat-pickr
                      v-model="patientInfo.op_start_date"
                      class="form-control"
                      :config="{ enableTime: true, dateFormat: 'd.m.Y H:i' }"
                      :disabled="readOnly"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group
                    label="OP end"
                    label-for="op_end_date"
                    label-cols-md="4"
                  >
                    <flat-pickr
                      v-model="patientInfo.op_end_date"
                      class="form-control"
                      :config="{ enableTime: true, dateFormat: 'd.m.Y H:i' }"
                      :disabled="readOnly"
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="12">
                  <b-form-group
                    label="Skin to skin [min]"
                    label-for="interval"
                    label-cols-md="4"
                  >
                    <div class="vertical-align">
                      {{ skin_2_skin }}
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
          <b-col cols="12">
            <b-col cols="12">
              <b-form-group
                label="MIC technique"
                label-for="intraop_mic_technique"
                label-cols-md="4"
              >
                <v-select
                  v-model="patientInfo.intraop_MIC_technique"
                  label="value"
                  :options="options"
                  :disabled="readOnly"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-card class="bordered-card" title="Conversion to sternotomy">
                <b-row>
                  <b-col cols="12">
                    <b-form-group label="---" label-for="---" label-cols-md="4">
                      <v-select
                        v-model="patientInfo.intraop_conversion_to_sternotomy"
                        label="value"
                        :options="options"
                        :disabled="readOnly"
                      />
                    </b-form-group>
                  </b-col>

                  <b-col cols="12">
                    <b-form-group
                      label="Reason for conversion"
                      label-for="intraop_reason_for_conversion"
                      label-cols-md="4"
                    >
                      <b-form-textarea
                        v-model="patientInfo.intraop_reason_for_conversion"
                        rows="3"
                        placeholder="Reason for conversion"
                        :readonly="readOnly"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="Mitral or Tricuspid Surgery"
                label-for="mitral_tricuspid_surgery"
                label-cols-md="4"
              >
                <v-select
                  v-model="patientInfo.intraop_op_mitral_or_tricuspid_surgery"
                  label="value"
                  :options="optionsMitralTricuspid"
                  :disabled="readOnly"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="Access Type"
                label-for="op_access"
                label-cols-md="4"
              >
                <v-select
                  v-model="patientInfo.intraop_op_access"
                  label="value"
                  :options="optionsOpAccess"
                  :disabled="readOnly"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="Type of Minimally Invasive Surgery"
                label-for="type_of_minimally_invasive_surgery"
                label-cols-md="4"
              >
                <v-select
                  v-model="
                    patientInfo.intraop_op_type_of_minimally_invasive_surgery
                  "
                  label="value"
                  :options="optionsMinimallyInvasiveSurgery"
                  :disabled="readOnly"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group
                label="Type of Transcatheter Access"
                label-for="type_of_transcatheter_access"
                label-cols-md="4"
              >
                <v-select
                  v-model="patientInfo.intraop_op_type_of_transcatheter_access"
                  label="value"
                  :options="optionsTranscatheterAccess"
                  :disabled="readOnly"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="Intention to Repair After Cusp Analysis"
                label-for="intraop_op_intention_to_repair_after_cusp_analysis"
                label-cols-md="4"
              >
                <b-form-input
                  id="intraop_op_intention_to_repair_after_cusp_analysis"
                  v-model="
                    patientInfo.intraop_op_intention_to_repair_after_cusp_analysis
                  "
                  placeholder="Intention to Repair After Cusp Analysis"
                  :readonly="readOnly"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group
                label="Anesthesia Type"
                label-for="anesthesia_type"
                label-cols-md="4"
              >
                <v-select
                  v-model="patientInfo.intraop_op_anesthesia"
                  label="value"
                  :options="optionsAnesthesiaType"
                  :disabled="readOnly"
                />
              </b-form-group>
            </b-col>
          </b-col>
        </b-row>
      </b-col>
      <b-col md="6">
        <b-row>
          <b-col md="12">
            <b-card class="bordered-card" title="Concomitant procedures">
              <b-row>
                <b-col cols="12">
                  <b-form-group label="---" label-for="---" label-cols-md="4">
                    <v-select
                      v-model="patientInfo.intraop_concomitant_procedures"
                      label="value"
                      :options="options"
                      :disabled="readOnly"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <b-form-group
                    label="Type"
                    label-for="intraop_concomitant_procedures_type"
                    label-cols-md="4"
                  >
                    <b-form-input
                      v-model="patientInfo.intraop_concomitant_procedures_type"
                      id="intraop_concomitant_procedures_type"
                      placeholder="Type"
                      :readonly="readOnly"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                    label="ASD PFO closure"
                    label-for="intraop_asd_pfo_closure"
                    label-cols-md="4"
                  >
                    <v-select
                      id="asd_pfo_closure"
                      v-model="patientInfo.intraop_asd_pfo_closure"
                      label="value"
                      :options="options"
                      :disabled="readOnly"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                    label="LAA closure"
                    label-for="intraop_laa_closure"
                    label-cols-md="4"
                  >
                    <v-select
                      id="laa_closure"
                      v-model="patientInfo.intraop_laa_closure"
                      label="value"
                      :options="options"
                      :disabled="readOnly"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                    label="Rhythm ablation therapy"
                    label-for="intraop_rhythm_ablation_therapy"
                    label-cols-md="4"
                  >
                    <v-select
                      id="rhythm_ablation_therapy"
                      v-model="patientInfo.intraop_rhythm_ablation_therapy"
                      label="value"
                      :options="options"
                      :disabled="readOnly"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                    label="Pacemaker lead extraction"
                    label-for="intraop_pacemaker_lead_extraction"
                    label-cols-md="4"
                  >
                    <v-select
                      id="pacemaker_lead_extraction"
                      v-model="patientInfo.intraop_pacemaker_lead_extraction"
                      label="value"
                      :options="options"
                      :disabled="readOnly"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                    label="Additional Procedures Performed"
                    label-for="additional_procedures_performed"
                    label-cols-md="4"
                  >
                    <v-select
                      v-model="
                        patientInfo.intraop_concomitant_procedures_additional_procedures_performed
                      "
                      label="value"
                      :options="options"
                      :disabled="readOnly"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                    label="Simultaneous Maze Procedure"
                    label-for="simultaneous_maze"
                    label-cols-md="4"
                  >
                    <v-select
                      v-model="
                        patientInfo.intraop_concomitant_procedures_simultaneous_maze
                      "
                      label="value"
                      :options="options"
                      :disabled="readOnly"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                    label="Maze Type"
                    label-for="maze_type"
                    label-cols-md="4"
                  >
                    <v-select
                      v-model="
                        patientInfo.intraop_concomitant_procedures_maze_type
                      "
                      label="value"
                      :options="optionsMazeType"
                      :disabled="readOnly"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                    label="Simultaneous LAA Closure"
                    label-for="simultaneous_laa_closure"
                    label-cols-md="4"
                  >
                    <v-select
                      v-model="
                        patientInfo.intraop_concomitant_procedures_simultaneous_laa_closure
                      "
                      label="value"
                      :options="options"
                      :disabled="readOnly"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                    label="Simultaneous Aortic Valve Procedure"
                    label-for="simultaneous_aortic_valve"
                    label-cols-md="4"
                  >
                    <v-select
                      v-model="
                        patientInfo.intraop_concomitant_procedures_simultaneous_aortic_valve
                      "
                      label="value"
                      :options="optionsAorticValve"
                      :disabled="readOnly"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                    label="Simultaneous CABG Procedure"
                    label-for="simultaneous_cabg"
                    label-cols-md="4"
                  >
                    <v-select
                      v-model="
                        patientInfo.intraop_concomitant_procedures_simultaneous_cabg
                      "
                      label="value"
                      :options="optionsCABG"
                      :disabled="readOnly"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                    label="Simultaneous Aorta Procedure"
                    label-for="simultaneous_aorta"
                    label-cols-md="4"
                  >
                    <v-select
                      v-model="
                        patientInfo.intraop_concomitant_procedures_simultaneous_aorta
                      "
                      label="value"
                      :options="optionsAorta"
                      :disabled="readOnly"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                    label="Other Additional Procedures"
                    label-for="other_additional_procedures"
                    label-cols-md="4"
                  >
                    <v-select
                      v-model="
                        patientInfo.intraop_concomitant_procedures_other_additional_procedures
                      "
                      label="value"
                      :options="options"
                      :disabled="readOnly"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                    label="Specify Additional Concomitant Procedures"
                    label-for="intraop_concomitant_procedures_other_additional_procedures_specify"
                    label-cols-md="4"
                  >
                    <b-form-textarea
                      id="intraop_concomitant_procedures_other_additional_procedures_specify"
                      v-model="
                        patientInfo.intraop_concomitant_procedures_other_additional_procedures_specify
                      "
                      rows="3"
                      placeholder="Specify Additional Concomitant Procedures"
                      :readonly="readOnly"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BFormDatepicker,
  BFormTextarea,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { dateMask, convert2NormalDateTime } from "@core/utils/utils";
import { mapState } from "vuex";
import moment from "moment";
import flatPickr from "vue-flatpickr-component";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BFormDatepicker,
    BFormTextarea,
    vSelect,
    flatPickr,
  },
  data() {
    return {
      patientInfo: {
        _id: null,
        op_start_date: "",
        op_end_date: "",
        intraop_MIC_technique: "",
        intraop_conversion_to_sternotomy: "",
        intraop_reason_for_conversion: "",
        intraop_concomitant_procedures: "",
        intraop_concomitant_procedures_type: "",
        intraop_asd_pfo_closure: "",
        intraop_laa_closure: "",
        intraop_rhythm_ablation_therapy: "",
        intraop_pacemaker_lead_extraction: "",
        intraop_concomitant_procedures_additional_procedures_performed: "",
        intraop_concomitant_procedures_simultaneous_maze: "",
        intraop_concomitant_procedures_maze_type: "",
        intraop_concomitant_procedures_simultaneous_laa_closure: "",
        intraop_concomitant_procedures_simultaneous_aortic_valve: "",
        intraop_concomitant_procedures_simultaneous_cabg: "",
        intraop_concomitant_procedures_simultaneous_aorta: "",
        intraop_concomitant_procedures_other_additional_procedures: "",
        intraop_concomitant_procedures_other_additional_procedures_specify: "",

        intraop_op_mitral_or_tricuspid_surgery: "",
        intraop_op_access: "",
        intraop_op_type_of_minimally_invasive_surgery: "",
        intraop_op_type_of_transcatheter_access: "",
        intraop_op_intention_to_repair_after_cusp_analysis: "",

        intraop_op_anesthesia: "",
      },
      value: "",
      options: ["yes", "no"],
      optionsMitralTricuspid: ["mitral", "tricuspid", "combined"],
      optionsOpAccess: [
        "Full sternotomy",
        "Partial sternotomy",
        "Mini-thoracotomy",
        "Transcatheter",
        "Other",
      ],
      optionsMinimallyInvasiveSurgery: [
        "Direct view",
        "3D endoscopic",
        "Robotic",
        "Other",
      ],
      optionsTranscatheterAccess: [
        "Femoral",
        "Apical",
        "Aortic",
        "Carotid",
        "Subclavian",
        "Other",
      ],
      optionsAnesthesiaType: ["General", "Local"],
      optionsMazeType: [
        "PV box only",
        "PV separate",
        "Left sided MAZE",
        "Biatrial MAZE",
      ],
      optionsAorticValve: [
        "None",
        "AV repair",
        "Mechanical AV replacement",
        "Biological AV replacement",
        "Other",
      ],
      optionsCABG: ["None", "1 x CABG", "2 x CABG", "3 x CABG", "> 3 x CABG"],
      optionsAorta: ["No", "Aortic root", "Ascending aorta", "Aortic arch"],

      v1: "",
      v2: "",
      date1: null,
      date2: null,
      dateNtim: null,
    };
  },
  computed: {
    ...mapState("patient", ["patient", "role"]),
    readOnly() {
      return this.role == "viewer" || this.role == "";
    },
    skin_2_skin() {
      if (!this.patientInfo.op_start_date || !this.patientInfo.op_end_date) {
        return "0";
      }
      if (
        this.patientInfo.op_start_date.length == 16 &&
        this.patientInfo.op_start_date.length == 16
      ) {
        let startDate = moment(
          convert2NormalDateTime(this.patientInfo.op_start_date)
        );
        let endDate = moment(
          convert2NormalDateTime(this.patientInfo.op_end_date)
        );
        return endDate.diff(startDate, "minute");
      }
      return "0";
    },
  },
  watch: {
    patient(v, ov) {
      if (v._id) {
        this.setPatientInfo(v);
      }
    },
  },
  mounted() {
    if (this.patient._id) {
      this.setPatientInfo(this.patient);
    }
  },
  methods: {
    dateMask,
    setPatientInfo(v) {
      this.patientInfo._id = v._id;

      this.patientInfo.op_start_date = v.op_start_date;
      this.patientInfo.op_end_date = v.op_end_date;
      this.patientInfo.intraop_MIC_technique = v.intraop_MIC_technique;
      this.patientInfo.intraop_conversion_to_sternotomy =
        v.intraop_conversion_to_sternotomy;
      this.patientInfo.intraop_reason_for_conversion =
        v.intraop_reason_for_conversion;
      this.patientInfo.intraop_concomitant_procedures =
        v.intraop_concomitant_procedures;
      this.patientInfo.intraop_concomitant_procedures_type =
        v.intraop_concomitant_procedures_type;
      this.patientInfo.intraop_asd_pfo_closure = v.intraop_asd_pfo_closure;
      this.patientInfo.intraop_laa_closure = v.intraop_laa_closure;
      this.patientInfo.intraop_rhythm_ablation_therapy =
        v.intraop_rhythm_ablation_therapy;
      this.patientInfo.intraop_pacemaker_lead_extraction =
        v.intraop_pacemaker_lead_extraction;

      this.patientInfo.intraop_op_mitral_or_tricuspid_surgery =
        v.intraop_op_mitral_or_tricuspid_surgery;
      this.patientInfo.intraop_op_access = v.intraop_op_access;
      this.patientInfo.intraop_op_type_of_minimally_invasive_surgery =
        v.intraop_op_type_of_minimally_invasive_surgery;
      this.patientInfo.intraop_op_type_of_transcatheter_access =
        v.intraop_op_type_of_transcatheter_access;
      this.patientInfo.intraop_op_intention_to_repair_after_cusp_analysis =
        v.intraop_op_intention_to_repair_after_cusp_analysis;

      this.patientInfo.intraop_op_anesthesia = v.intraop_op_anesthesia;
      this.patientInfo.intraop_concomitant_procedures_additional_procedures_performed =
        v.intraop_concomitant_procedures_additional_procedures_performed;
      this.patientInfo.intraop_concomitant_procedures_simultaneous_maze =
        v.intraop_concomitant_procedures_simultaneous_maze;
      this.patientInfo.intraop_concomitant_procedures_maze_type =
        v.intraop_concomitant_procedures_maze_type;
      this.patientInfo.intraop_concomitant_procedures_simultaneous_laa_closure =
        v.intraop_concomitant_procedures_simultaneous_laa_closure;
      this.patientInfo.intraop_concomitant_procedures_simultaneous_aortic_valve =
        v.intraop_concomitant_procedures_simultaneous_aortic_valve;
      this.patientInfo.intraop_concomitant_procedures_simultaneous_cabg =
        v.intraop_concomitant_procedures_simultaneous_cabg;
      this.patientInfo.intraop_concomitant_procedures_simultaneous_aorta =
        v.intraop_concomitant_procedures_simultaneous_aorta;
      this.patientInfo.intraop_concomitant_procedures_other_additional_procedures =
        v.intraop_concomitant_procedures_other_additional_procedures;
      this.patientInfo.intraop_concomitant_procedures_other_additional_procedures_specify =
        v.intraop_concomitant_procedures_other_additional_procedures_specify;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
