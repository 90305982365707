<template>
  <b-card title="MV" sub-title="Information about MV">
    <b-row>
      <b-col md="12">
        <b-row>
          <b-col cols="12">
            <b-card class="bordered-card" title="MV surgery">
              <b-row>
                <b-col md="6">
                  <b-col cols="12">
                    <b-form-group
                      label="MV surgery"
                      label-for="---"
                      label-cols-md="4"
                    >
                      <v-select
                        v-model="patientInfo.intraop_op_mv"
                        label="value"
                        :options="options"
                        :disabled="readOnly"
                      />
                    </b-form-group>
                  </b-col>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="6">
                  <b-col cols="12">
                    <b-form-group
                      label="Surgery type"
                      label-for="intraop_op_mv_surgery_type"
                      label-cols-md="4"
                    >
                      <v-select
                        v-model="patientInfo.intraop_op_mv_surgery_type"
                        label="value"
                        :options="options1"
                        :disabled="readOnly"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group
                      label="Technique"
                      label-for="intraop_op_mv_technique"
                      label-cols-md="4"
                    >
                      <v-select
                        v-model="patientInfo.intraop_op_mv_technique"
                        label="value"
                        :options="options2"
                        :disabled="readOnly"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group
                      label="Mitral Valve Disease"
                      label-for="mitral_valve_disease"
                      label-cols-md="4"
                    >
                      <v-select
                        v-model="patientInfo.intraop_mv_mitral_valve_disease"
                        label="value"
                        :options="optionsMitralValveDisease"
                        :disabled="readOnly"
                      />
                    </b-form-group>
                  </b-col>
                </b-col>
                <b-col md="6">
                  <b-col cols="12">
                    <b-form-group
                      label="Etiology of Secondary MR"
                      label-for="etiology_of_secondary_mr"
                      label-cols-md="4"
                    >
                      <v-select
                        v-model="
                          patientInfo.intraop_mv_etiology_of_secondary_mr
                        "
                        label="value"
                        :options="optionsEtiologySecondaryMR"
                        :disabled="readOnly"
                      />
                    </b-form-group>
                  </b-col>

                  <b-col cols="12">
                    <b-form-group
                      label="Annulus Diameter Antero-Posterior [mm]"
                      label-for="mv_annulus_diameter_antero_posterior"
                      label-cols-md="4"
                    >
                      <b-form-input
                        v-model="
                          patientInfo.intraop_mv_annulus_diameter_antero_posterior
                        "
                        id="mv_annulus_diameter_antero_posterior"
                        type="number"
                        placeholder="MV Annulus Diameter Antero-Posterior"
                        :readonly="readOnly"
                      />
                    </b-form-group>
                  </b-col>

                  <b-col cols="12">
                    <b-form-group
                      label="Type of Surgery"
                      label-for="type_of_mv_surgery_intervention"
                      label-cols-md="4"
                    >
                      <v-select
                        v-model="
                          patientInfo.intraop_mv_type_of_mv_surgery_intervention
                        "
                        label="value"
                        :options="optionsMVTypeOfSurgeryIntervention"
                        :disabled="readOnly"
                      />
                    </b-form-group>
                  </b-col>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
          <b-col md="6">
            <b-card class="bordered-card" title="Repair">
              <b-row>
                <b-col cols="12">
                  <b-form-group
                    label="Annuloplasty"
                    label-for="intraop_op_tv_repair_annuloplasty"
                    label-cols-md="4"
                  >
                    <v-select
                      v-model="
                        patientInfo.intraop_op_mv_surgery_technique_mv_repair_annuloplasty
                      "
                      label="value"
                      :options="options"
                      :disabled="readOnly"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                    label="Triangular resection"
                    label-for="intraop_op_mv_surgery_technique_mv_repair_triangular_resection"
                    label-cols-md="4"
                  >
                    <v-select
                      v-model="
                        patientInfo.intraop_op_mv_surgery_technique_mv_repair_triangular_resection
                      "
                      label="value"
                      :options="options"
                      :disabled="readOnly"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                    label="Quadrangular resection"
                    label-for="intraop_op_mv_surgery_technique_mv_repair_quadrangular_resection"
                    label-cols-md="4"
                  >
                    <v-select
                      v-model="
                        patientInfo.intraop_op_mv_surgery_technique_mv_repair_quadrangular_resection
                      "
                      label="value"
                      :options="options"
                      :disabled="readOnly"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                    label="Chordae"
                    label-for="intraop_op_mv_surgery_technique_mv_repair_chordae"
                    label-cols-md="4"
                  >
                    <v-select
                      v-model="
                        patientInfo.intraop_op_mv_surgery_technique_mv_repair_chordae
                      "
                      label="value"
                      :options="options"
                      :disabled="readOnly"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                    label="Type of Surgical MV Repair"
                    label-for="type_of_surgical_mv_repair"
                    label-cols-md="4"
                  >
                    <v-select
                      v-model="
                        patientInfo.intraop_mv_type_of_surgical_mv_repair
                      "
                      label="value"
                      :options="optionsSurgicalMVRepair"
                      :disabled="readOnly"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                    label="Other Surgical MV Repair"
                    label-for="intraop_mv_other_surgical_mv_repair"
                    label-cols-md="4"
                  >
                    <b-form-input
                      id="intraop_mv_other_surgical_mv_repair"
                      v-model="patientInfo.intraop_mv_other_surgical_mv_repair"
                      placeholder="Other Surgical MV Repair"
                      :readonly="readOnly"
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="12">
                  <b-form-group
                    label="Surgical MV Annuloplasty Type"
                    label-for="surgical_mv_annuloplasty_type"
                    label-cols-md="4"
                  >
                    <v-select
                      v-model="
                        patientInfo.intraop_mv_surgical_mv_annuloplasty_type
                      "
                      label="value"
                      :options="optionsSurgicalMVAnnuloplastyType"
                      :disabled="readOnly"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                    label="Model of Surgical MV Annuloplasty Ring"
                    label-for="intraop_model_of_surgical_mv_annuloplasty_ring_model"
                    label-cols-md="4"
                  >
                    <b-form-input
                      id="intraop_model_of_surgical_mv_annuloplasty_ring_model"
                      v-model="
                        patientInfo.intraop_model_of_surgical_mv_annuloplasty_ring_model
                      "
                      placeholder="Model of Surgical MV Annuloplasty Ring"
                      :readonly="readOnly"
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="12">
                  <b-form-group
                    label="Size of Surgical MV Annuloplasty Ring [mm]"
                    label-for="surgical_mv_annuloplasty_ring_size"
                    label-cols-md="4"
                  >
                    <b-form-input
                      v-model="
                        patientInfo.intraop_size_of_surgical_mv_annuloplasty_ring_size
                      "
                      id="surgical_mv_annuloplasty_ring_size"
                      type="number"
                      placeholder="Size of Surgical MV Annuloplasty Ring"
                      :readonly="readOnly"
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="12">
                  <b-form-group
                    label="Subannular MV Repair Type"
                    label-for="subannular_mv_repair_type"
                    label-cols-md="4"
                  >
                    <v-select
                      v-model="patientInfo.intraop_mv_subannular_mv_repair_type"
                      label="value"
                      :options="optionsSubannularMVRepairType"
                      :disabled="readOnly"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                    label="PM Repositioning Type"
                    label-for="pm_repositioning_type"
                    label-cols-md="4"
                  >
                    <v-select
                      v-model="patientInfo.intraop_mv_pm_repositioning_type"
                      label="value"
                      :options="optionsPMRepositioningType"
                      :disabled="readOnly"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                    label="Other Subannular MV Repair"
                    label-for="intraop_mv_other_subannular_mv_repair"
                    label-cols-md="4"
                  >
                    <b-form-input
                      id="intraop_mv_other_subannular_mv_repair"
                      v-model="
                        patientInfo.intraop_mv_other_subannular_mv_repair
                      "
                      placeholder="Other Subannular MV Repair"
                      :readonly="readOnly"
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="12">
                  <b-form-group
                    label="Surgical MV Leaflet Repair Type"
                    label-for="surgical_mv_leaflet_repair_type"
                    label-cols-md="4"
                  >
                    <v-select
                      v-model="
                        patientInfo.intraop_mv_surgical_mv_leaflet_repair_type
                      "
                      label="value"
                      :options="optionsSurgicalMVLeafletRepairType"
                      :disabled="readOnly"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                    label="Edge-to-Edge Repair Location"
                    label-for="edge_to_edge_repair_location"
                    label-cols-md="4"
                  >
                    <v-select
                      v-model="
                        patientInfo.intraop_mv_surgical_edge_to_edge_repair_location
                      "
                      label="value"
                      :options="optionsEdgeToEdgeRepairLocation"
                      :disabled="readOnly"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                    label="MV Repair Patch Type"
                    label-for="repair_patch_type"
                    label-cols-md="4"
                  >
                    <v-select
                      v-model="patientInfo.intraop_mv_repair_patch_type"
                      label="value"
                      :options="optionsRepairPatchType"
                      :disabled="readOnly"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                    label="Other Patch Materials in MV Repair"
                    label-for="other_patch_materials_in_mv_repair"
                    label-cols-md="4"
                  >
                    <v-select
                      v-model="
                        patientInfo.intraop_other_patch_materials_in_mv_repair
                      "
                      label="value"
                      :options="optionsOtherPatchMaterials"
                      :disabled="readOnly"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card>
            <b-card class="bordered-card" title="Transcatheter MV Repair">
              <b-col cols="12">
                <b-form-group
                  label="Transcatheter MV Repair Type"
                  label-for="transcatheter_mv_repair_type"
                  label-cols-md="4"
                >
                  <v-select
                    v-model="
                      patientInfo.intraop_mv_transcatheter_mv_repair_type
                    "
                    label="value"
                    :options="optionsTranscatheterMVRepairType"
                    :disabled="readOnly"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="Other Transcatheter MV Repair"
                  label-for="intraop_mv_transcatheter_mv_repair_other"
                  label-cols-md="4"
                >
                  <b-form-input
                    id="intraop_mv_transcatheter_mv_repair_other"
                    v-model="
                      patientInfo.intraop_mv_transcatheter_mv_repair_other
                    "
                    placeholder="Other Transcatheter MV Repair"
                    :readonly="readOnly"
                  />
                </b-form-group>
              </b-col>

              <b-col cols="12">
                <b-form-group
                  label="Transcatheter MV Repair Brand"
                  label-for="transcatheter_mv_repair_brand"
                  label-cols-md="4"
                >
                  <v-select
                    v-model="
                      patientInfo.intraop_mv_transcatheter_mv_repair_brand
                    "
                    label="value"
                    :options="optionsTranscatheterMVRepairBrand"
                    :disabled="readOnly"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="Other Transcatheter MV Repair Brand"
                  label-for="intraop_mv_transcatheter_mv_repair_brand_other"
                  label-cols-md="4"
                >
                  <b-form-input
                    id="intraop_mv_transcatheter_mv_repair_brand_other"
                    v-model="
                      patientInfo.intraop_mv_transcatheter_mv_repair_brand_other
                    "
                    placeholder="Other Transcatheter MV Repair Brand"
                    :readonly="readOnly"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="TMVR Access Site"
                  label-for="tmvr_access_site"
                  label-cols-md="4"
                >
                  <v-select
                    v-model="patientInfo.intraop_mv_tmvr_access_site"
                    label="value"
                    :options="optionsTMVRAccessSite"
                    :disabled="readOnly"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="TMVR Bioprosthesis Brand"
                  label-for="tmvr_bioprosthesis_brand"
                  label-cols-md="4"
                >
                  <v-select
                    v-model="patientInfo.intraop_mv_tmvr_bioprosthesis_brand"
                    label="value"
                    :options="optionsTMVRBioprosthesisBrand"
                    :disabled="readOnly"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="Other TMVR Bioprosthesis Brand"
                  label-for="intraop_mv_tmvr_bioprosthesis_brand_other"
                  label-cols-md="4"
                >
                  <b-form-input
                    id="intraop_mv_tmvr_bioprosthesis_brand_other"
                    v-model="
                      patientInfo.intraop_mv_tmvr_bioprosthesis_brand_other
                    "
                    placeholder="Other TMVR Bioprosthesis Brand"
                    :readonly="readOnly"
                  />
                </b-form-group>
              </b-col>

              <b-col cols="12">
                <b-form-group
                  label="MV TMVR Bioprosthesis Size [mm]"
                  label-for="mv_tmvr_bioprosthesis_size"
                  label-cols-md="4"
                >
                  <b-form-input
                    v-model="patientInfo.intraop_mv_tmvr_bioprosthesis_size"
                    id="mv_tmvr_bioprosthesis_size"
                    type="number"
                    placeholder="MV TMVR Bioprosthesis Size"
                    :readonly="readOnly"
                  />
                </b-form-group>
              </b-col>
            </b-card>
            <b-card class="bordered-card" title="PML">
              <b-col cols="12">
                <b-form-group
                  label="PML Normal"
                  label-for="pml_normal"
                  label-cols-md="4"
                >
                  <v-select
                    v-model="patientInfo.intraop_mv_pml_normal"
                    label="value"
                    :options="options"
                    :disabled="readOnly"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="PML Prolapse"
                  label-for="pml_prolapse"
                  label-cols-md="4"
                >
                  <v-select
                    v-model="patientInfo.intraop_mv_pml_prolapse"
                    label="value"
                    :options="options"
                    :disabled="readOnly"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="PML Prolapse Segment"
                  label-for="pml_prolapse_segment"
                  label-cols-md="4"
                >
                  <v-select
                    v-model="patientInfo.intraop_mv_pml_prolapse_segment"
                    label="value"
                    :options="optionsPMLProlapseSegment"
                    :disabled="readOnly"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="PML Perforation"
                  label-for="pml_perforation"
                  label-cols-md="4"
                >
                  <v-select
                    v-model="patientInfo.intraop_mv_pml_perforation"
                    label="value"
                    :options="options"
                    :disabled="readOnly"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="PML Perforation Segment"
                  label-for="pml_perforation_segment"
                  label-cols-md="4"
                >
                  <v-select
                    v-model="patientInfo.intraop_mv_pml_perforation_segment"
                    label="value"
                    :options="optionsPMLPerforationSegment"
                    :disabled="readOnly"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="PML Calcification"
                  label-for="pml_calcification"
                  label-cols-md="4"
                >
                  <v-select
                    v-model="patientInfo.intraop_mv_pml_calcification"
                    label="value"
                    :options="options"
                    :disabled="readOnly"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="PML Calcification Segment"
                  label-for="pml_calcification_segment"
                  label-cols-md="4"
                >
                  <v-select
                    v-model="patientInfo.intraop_mv_pml_calcification_segment"
                    label="value"
                    :options="optionsPMLCalcificationSegment"
                    :disabled="readOnly"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="PML Vegetation"
                  label-for="pml_vegetation"
                  label-cols-md="4"
                >
                  <v-select
                    v-model="patientInfo.intraop_mv_pml_vegetation"
                    label="value"
                    :options="options"
                    :disabled="readOnly"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="PML Vegetation Segment"
                  label-for="pml_vegetation_segment"
                  label-cols-md="4"
                >
                  <v-select
                    v-model="patientInfo.intraop_mv_pml_vegetation_segment"
                    label="value"
                    :options="optionsPMLVegetationSegment"
                    :disabled="readOnly"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="PML Tenting"
                  label-for="pml_tenting"
                  label-cols-md="4"
                >
                  <v-select
                    v-model="patientInfo.intraop_mv_pml_tenting"
                    label="value"
                    :options="options"
                    :disabled="readOnly"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="PML Tenting Segment"
                  label-for="pml_tenting_segment"
                  label-cols-md="4"
                >
                  <v-select
                    v-model="patientInfo.intraop_mv_pml_tenting_segment"
                    label="value"
                    :options="optionsPMLTentingSegment"
                    :disabled="readOnly"
                  />
                </b-form-group>
              </b-col>
            </b-card>
          </b-col>
          <b-col md="6">
            <b-card class="bordered-card" title="Replacement">
              <b-row>
                <b-col cols="12">
                  <b-form-group label="---" label-for="---" label-cols-md="4">
                    <v-select
                      v-model="patientInfo.intraop_op_mv_replacement"
                      label="value"
                      :options="options"
                      :disabled="readOnly"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                    label="Prosthesis type"
                    label-for="intraop_op_mv_surgery_technique_mv_replacement_prosthesis_type"
                    label-cols-md="4"
                  >
                    <b-form-input
                      v-model="
                        patientInfo.intraop_op_mv_surgery_technique_mv_replacement_prosthesis_type
                      "
                      id="intraop_op_mv_surgery_technique_mv_replacement_prosthesis_type"
                      placeholder="Prosthesis type"
                      :readonly="readOnly"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                    label="Prosthesis size"
                    label-for="intraop_op_mv_surgery_technique_mv_replacement_prosthesis_size"
                    label-cols-md="4"
                  >
                    <b-form-input
                      v-model="
                        patientInfo.intraop_op_mv_surgery_technique_mv_replacement_prosthesis_size
                      "
                      id="intraop_op_mv_surgery_technique_mv_replacement_prosthesis_size"
                      type="number"
                      placeholder="Prosthesis size"
                      :readonly="readOnly"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                    label="Surgical MV Replacement Type"
                    label-for="surgical_mv_replacement_type"
                    label-cols-md="4"
                  >
                    <v-select
                      v-model="
                        patientInfo.intraop_mv_surgical_mv_replacement_type
                      "
                      label="value"
                      :options="optionsSurgicalMVReplacementType"
                      :disabled="readOnly"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                    label="Replacement Chordal Sparing"
                    label-for="replacement_chordal_sparing"
                    label-cols-md="4"
                  >
                    <v-select
                      v-model="
                        patientInfo.intraop_mv_replacement_chordal_sparing
                      "
                      label="value"
                      :options="optionsReplacementChordalSparing"
                      :disabled="readOnly"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                    label="Mechanical Valve Brand"
                    label-for="mechanical_brand"
                    label-cols-md="4"
                  >
                    <v-select
                      v-model="patientInfo.intraop_mv_mechanical_brand"
                      label="value"
                      :options="optionsMechanicalBrand"
                      :disabled="readOnly"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                    label="Other Mechanical Prosthesis Brand"
                    label-for="intraop_mv_mechanical_prosthesis_brand_other"
                    label-cols-md="4"
                  >
                    <b-form-input
                      id="intraop_mv_mechanical_prosthesis_brand_other"
                      v-model="
                        patientInfo.intraop_mv_mechanical_prosthesis_brand_other
                      "
                      placeholder="Other Mechanical Prosthesis Brand"
                      :readonly="readOnly"
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="12">
                  <b-form-group
                    label="MV Mechanical Prosthesis Size [mm]"
                    label-for="mv_mechanical_prosthesis_size"
                    label-cols-md="4"
                  >
                    <b-form-input
                      v-model="
                        patientInfo.intraop_mv_mechanical_prosthesis_size
                      "
                      id="mv_mechanical_prosthesis_size"
                      type="number"
                      placeholder="MV Mechanical Prosthesis Size"
                      :readonly="readOnly"
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="12">
                  <b-form-group
                    label="Bioprothesis Valve Brand"
                    label-for="bioprothesis_brand"
                    label-cols-md="4"
                  >
                    <v-select
                      v-model="patientInfo.intraop_mv_bioprothesis_brand"
                      label="value"
                      :options="optionsBioprothesisBrand"
                      :disabled="readOnly"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                    label="Other Bioprosthesis Brand"
                    label-for="intraop_mv_bioprosthesis_brand_other"
                    label-cols-md="4"
                  >
                    <b-form-input
                      id="intraop_mv_bioprosthesis_brand_other"
                      v-model="patientInfo.intraop_mv_bioprosthesis_brand_other"
                      placeholder="Other Bioprosthesis Brand"
                      :readonly="readOnly"
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="12">
                  <b-form-group
                    label="MV Bioprosthesis Size [mm]"
                    label-for="mv_bioprosthesis_size"
                    label-cols-md="4"
                  >
                    <b-form-input
                      v-model="patientInfo.intraop_mv_bioprosthesis_size"
                      id="mv_bioprosthesis_size"
                      type="number"
                      placeholder="MV Bioprosthesis Size"
                      :readonly="readOnly"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card>
            <b-card class="bordered-card" title="Other">
              <b-row>
                <b-col cols="12">
                  <b-form-group label="---" label-for="---" label-cols-md="4">
                    <v-select
                      v-model="patientInfo.intraop_op_mv_others"
                      label="value"
                      :options="options"
                      :disabled="readOnly"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                    label="Explain"
                    label-for="mitraclip"
                    label-cols-md="4"
                  >
                    <b-form-textarea
                      v-model="patientInfo.intraop_op_mv_others_explain"
                      rows="3"
                      placeholder="Explain"
                      :readonly="readOnly"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card>
            <b-card
              class="bordered-card"
              title="Transcatheter Edge-to-Edge Repair (TEER)"
            >
              <b-col cols="12">
                <b-form-group
                  label="TEER Size Clip Type"
                  label-for="intraop_mv_TEER_size_clip_type"
                  label-cols-md="4"
                >
                  <b-form-input
                    id="intraop_mv_TEER_size_clip_type"
                    v-model="patientInfo.intraop_mv_TEER_size_clip_type"
                    placeholder="TEER Size Clip Type"
                    :readonly="readOnly"
                  />
                </b-form-group>
              </b-col>

              <b-col cols="12">
                <b-form-group
                  label="MV TEER Number of Clips"
                  label-for="mv_teer_number_clips"
                  label-cols-md="4"
                >
                  <b-form-input
                    v-model="patientInfo.intraop_mv_TEER_number_clips"
                    id="mv_teer_number_clips"
                    type="number"
                    placeholder="MV TEER Number of Clips"
                    :readonly="readOnly"
                  />
                </b-form-group>
              </b-col>

              <b-col cols="12">
                <b-form-group
                  label="TEER First Clip Position"
                  label-for="teer_first_clip_position"
                  label-cols-md="4"
                >
                  <v-select
                    v-model="patientInfo.intraop_mv_TEER_first_clip_position"
                    label="value"
                    :options="optionsTEERFirstClipPosition"
                    :disabled="readOnly"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="TEER Second Clip Position"
                  label-for="teer_second_clip_position"
                  label-cols-md="4"
                >
                  <v-select
                    v-model="patientInfo.intraop_mv_TEER_second_clip_position"
                    label="value"
                    :options="optionsTEERSecondClipPosition"
                    :disabled="readOnly"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="TEER Third Clip Position"
                  label-for="teer_third_clip_position"
                  label-cols-md="4"
                >
                  <v-select
                    v-model="patientInfo.intraop_mv_TEER_third_clip_position"
                    label="value"
                    :options="optionsTEERThirdClipPosition"
                    :disabled="readOnly"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="TEER Other Clip Position"
                  label-for="teer_other_clip_position"
                  label-cols-md="4"
                >
                  <v-select
                    v-model="patientInfo.intraop_mv_TEER_other_clip_position"
                    label="value"
                    :options="optionsTEEROtherClipPosition"
                    :disabled="readOnly"
                  />
                </b-form-group>
              </b-col>
            </b-card>
            <b-card class="bordered-card" title="AML">
              <b-col cols="12">
                <b-form-group
                  label="AML Normal"
                  label-for="aml_normal"
                  label-cols-md="4"
                >
                  <v-select
                    v-model="patientInfo.intraop_mv_aml_normal"
                    label="value"
                    :options="options"
                    :disabled="readOnly"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="AML Prolapse"
                  label-for="aml_prolapse"
                  label-cols-md="4"
                >
                  <v-select
                    v-model="patientInfo.intraop_mv_aml_prolapse"
                    label="value"
                    :options="options"
                    :disabled="readOnly"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="AML Prolapse Segment"
                  label-for="aml_prolapse_segment"
                  label-cols-md="4"
                >
                  <v-select
                    v-model="patientInfo.intraop_mv_aml_prolapse_segment"
                    label="value"
                    :options="optionsAMLProlapseSegment"
                    :disabled="readOnly"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="AML Perforation"
                  label-for="aml_perforation"
                  label-cols-md="4"
                >
                  <v-select
                    v-model="patientInfo.intraop_mv_aml_perforation"
                    label="value"
                    :options="options"
                    :disabled="readOnly"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="AML Perforation Segment"
                  label-for="aml_perforation_segment"
                  label-cols-md="4"
                >
                  <v-select
                    v-model="patientInfo.intraop_mv_aml_perforation_segment"
                    label="value"
                    :options="optionsAMLPerforationSegment"
                    :disabled="readOnly"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="AML Calcification"
                  label-for="aml_calcification"
                  label-cols-md="4"
                >
                  <v-select
                    v-model="patientInfo.intraop_mv_aml_calcification"
                    label="value"
                    :options="options"
                    :disabled="readOnly"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="AML Calcification Segment"
                  label-for="aml_calcification_segment"
                  label-cols-md="4"
                >
                  <v-select
                    v-model="patientInfo.intraop_mv_aml_calcification_segment"
                    label="value"
                    :options="optionsAMLCalcificationSegment"
                    :disabled="readOnly"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="AML Vegetation"
                  label-for="aml_vegetation"
                  label-cols-md="4"
                >
                  <v-select
                    v-model="patientInfo.intraop_mv_aml_vegetation"
                    label="value"
                    :options="options"
                    :disabled="readOnly"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="AML Vegetation Segment"
                  label-for="aml_vegetation_segment"
                  label-cols-md="4"
                >
                  <v-select
                    v-model="patientInfo.intraop_mv_aml_vegetation_segment"
                    label="value"
                    :options="optionsAMLVegetationSegment"
                    :disabled="readOnly"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="AML Tenting"
                  label-for="aml_tenting"
                  label-cols-md="4"
                >
                  <v-select
                    v-model="patientInfo.intraop_mv_aml_tenting"
                    label="value"
                    :options="options"
                    :disabled="readOnly"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="AML Tenting Segment"
                  label-for="aml_tenting_segment"
                  label-cols-md="4"
                >
                  <v-select
                    v-model="patientInfo.intraop_mv_aml_tenting_segment"
                    label="value"
                    :options="optionsAMLTentingSegment"
                    :disabled="readOnly"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="MV AML Length [mm]"
                  label-for="mv_aml_length"
                  label-cols-md="4"
                >
                  <b-form-input
                    v-model="patientInfo.intraop_mv_aml_length"
                    id="mv_aml_length"
                    type="number"
                    placeholder="MV AML Length"
                    :readonly="readOnly"
                  />
                </b-form-group>
              </b-col>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
      <b-col md="6">
        <b-row>
          <b-col cols="12"> </b-col>
          <b-col cols="12"> </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BFormDatepicker,
  BFormTextarea,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { dateMask } from "@core/utils/utils";
import { mapState } from "vuex";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BFormDatepicker,
    BFormTextarea,
    vSelect,
  },
  data() {
    return {
      patientInfo: {
        _id: null,
        intraop_op_mv: "",
        intraop_op_mv_surgery_type: "",
        intraop_op_mv_technique: "",
        intraop_op_mv_surgery_technique_mv_repair_annuloplasty: "",
        intraop_op_mv_surgery_technique_mv_repair_triangular_resection: "",
        intraop_op_mv_surgery_technique_mv_repair_quadrangular_resection: "",
        intraop_op_mv_surgery_technique_mv_repair_chordae: "",
        intraop_op_mv_others: "",
        intraop_op_mv_others_explain: "",
        intraop_op_mv_replacement: "",
        intraop_op_mv_surgery_technique_mv_replacement_prosthesis_type: "",
        intraop_op_mv_surgery_technique_mv_replacement_prosthesis_size: 0,
        intraop_mv_mitral_valve_disease: "",
        intraop_mv_etiology_of_secondary_mr: "",
        intraop_mv_pml_normal: "",
        intraop_mv_pml_prolapse: "",
        intraop_mv_pml_prolapse_segment: "",
        intraop_mv_pml_perforation: "",
        intraop_mv_pml_perforation_segment: "",
        intraop_mv_pml_calcification: "",
        intraop_mv_pml_calcification_segment: "",
        intraop_mv_pml_vegetation: "",
        intraop_mv_pml_vegetation_segment: "",
        intraop_mv_pml_tenting: "",
        intraop_mv_pml_tenting_segment: "",
        intraop_mv_aml_length: "",
        intraop_mv_annulus_diameter_antero_posterior: "",

        intraop_mv_aml_normal: "",
        intraop_mv_aml_prolapse: "",
        intraop_mv_aml_prolapse_segment: "",
        intraop_mv_aml_perforation: "",
        intraop_mv_aml_perforation_segment: "",
        intraop_mv_aml_calcification: "",
        intraop_mv_aml_calcification_segment: "",
        intraop_mv_aml_vegetation: "",
        intraop_mv_aml_vegetation_segment: "",
        intraop_mv_aml_tenting: "",
        intraop_mv_aml_tenting_segment: "",
        intraop_mv_type_of_mv_surgery_intervention: "",
        intraop_mv_type_of_surgical_mv_repair: "",
        intraop_mv_other_surgical_mv_repair: "",

        intraop_mv_surgical_mv_annuloplasty_type: "",
        intraop_model_of_surgical_mv_annuloplasty_ring_model: "",

        intraop_size_of_surgical_mv_annuloplasty_ring_size: "",

        intraop_mv_subannular_mv_repair_type: "",
        intraop_mv_pm_repositioning_type: "",
        intraop_mv_other_subannular_mv_repair: "",

        intraop_mv_surgical_mv_leaflet_repair_type: "",
        intraop_mv_surgical_edge_to_edge_repair_location: "",
        intraop_mv_repair_patch_type: "",
        intraop_other_patch_materials_in_mv_repair: "",
        intraop_mv_surgical_mv_replacement_type: "",
        intraop_mv_replacement_chordal_sparing: "",
        intraop_mv_mechanical_brand: "",
        intraop_mv_mechanical_prosthesis_brand_other: "",

        intraop_mv_mechanical_prosthesis_size: "",

        intraop_mv_bioprothesis_brand: "",
        intraop_mv_bioprosthesis_brand_other: "",

        intraop_mv_bioprosthesis_size: "",

        intraop_mv_transcatheter_mv_repair_type: "",
        intraop_mv_transcatheter_mv_repair_other: "",

        intraop_mv_transcatheter_mv_repair_brand: "",
        intraop_mv_transcatheter_mv_repair_brand_other: "",
        intraop_mv_TEER_size_clip_type: "",

        intraop_mv_TEER_number_clips: "",

        intraop_mv_TEER_first_clip_position: "",
        intraop_mv_TEER_second_clip_position: "",
        intraop_mv_TEER_third_clip_position: "",
        intraop_mv_TEER_other_clip_position: "",
        intraop_mv_tmvr_access_site: "",
        intraop_mv_tmvr_bioprosthesis_brand: "",
        intraop_mv_tmvr_bioprosthesis_brand_other: "",

        intraop_mv_tmvr_bioprosthesis_size: "",
      },
      options: ["yes", "no"],
      options1: [
        "MV repair open",
        "MVR MIC",
        "MV replace open",
        "MV replace MIC",
      ],
      options2: ["MV repair", "MV replacement"],
      optionsMitralValveDisease: [
        "Secondary",
        "Degenerative",
        "Mixed",
        "Rheumatic",
        "Endocarditis",
        "Congenital",
        "Trauma",
        "Papillary muscle rupture",
        "Unknown",
      ],
      optionsEtiologySecondaryMR: [
        "Ischemic cardiomyopathy",
        "Dilative cardiomyopathy",
        "Myocarditis",
        "Other",
        "Unknown",
      ],
      optionsPMLProlapseSegment: ["P1", "P2", "P3"],
      optionsPMLPerforationSegment: ["P1", "P2", "P3"],
      optionsPMLCalcificationSegment: ["P1", "P2", "P3"],
      optionsPMLVegetationSegment: ["P1", "P2", "P3"],
      optionsPMLTentingSegment: ["P1", "P2", "P3"],
      optionsAMLProlapseSegment: ["A1", "A2", "A3"],
      optionsAMLPerforationSegment: ["A1", "A2", "A3"],
      optionsAMLCalcificationSegment: ["A1", "A2", "A3"],
      optionsAMLVegetationSegment: ["A1", "A2", "A3"],
      optionsAMLTentingSegment: ["A1", "A2", "A3"],
      optionsMVTypeOfSurgeryIntervention: [
        "Surgical MV repair",
        "Surgical MV replacement",
        "Transcatheter MV repair",
        "Transcatheter MV replacement",
        "Conservative treatment",
      ],
      optionsSurgicalMVRepair: [
        "Annuloplasty",
        "Subannular repair",
        "Leaflet repair",
        "Other",
      ],
      optionsSurgicalMVAnnuloplastyType: [
        "none",
        "partial ring",
        "complete ring",
      ],
      optionsSubannularMVRepairType: [
        "PM repositioning",
        "PM approximation/ PM sling",
        "Cutting of secondary chordae",
        "PML augmentation with patch",
        "AML augmentation with patch",
        "none",
        "Other",
      ],
      optionsPMRepositioningType: [
        "posteromedial papillary muscle",
        "anterolateral papillary muscle",
        "both",
        "None",
      ],
      optionsSurgicalMVLeafletRepairType: [
        "quadrangular resection PML + sliding",
        "triangular resection PML",
        "plication PML",
        "neochordae PML loop technique",
        "neochordae PML single pairs",
        "neochordae AML loop technique",
        "neochordae AML single pairs",
        "edge-to-edge",
        "cleft closure",
        "folding plasty PML",
        "commisurotomy",
        "none",
      ],
      optionsEdgeToEdgeRepairLocation: [
        "A1-P1",
        "A2-P2",
        "A3-P3",
        "commissure",
      ],
      optionsRepairPatchType: [
        "Autologous pericardium (glutaraldehyde)",
        "Fresh autologous pericardium",
        "Xeno pericardium (glutaraldehyde)",
        "Other patch materials",
        "none",
      ],
      optionsOtherPatchMaterials: ["CardioCel", "CorMatrix", "PTFE", "Other"],
      optionsSurgicalMVReplacementType: [
        "Mechanical prosthesis",
        "Biological prosthesis",
      ],
      optionsReplacementChordalSparing: ["PML", "AML", "bileaflet", "None"],
      optionsMechanicalBrand: [
        "Abbott SJM",
        "Livanova Carbomedics",
        "Medtronic Hall",
        "Cryolife ON-X",
        "Other",
      ],
      optionsBioprothesisBrand: [
        "CE Perimount",
        "CE Magna/Magna Ease",
        "Medtronic Hancock",
        "Medtronic Mosaic",
        "St Jude Abbott Epic",
        "Other",
      ],
      optionsTranscatheterMVRepairType: [
        "Direct annuloplasty",
        "Coronary sinus device",
        "Edge-to-Edge",
        "Annuloplasty + Edge-to-Edge",
        "Other",
      ],
      optionsTranscatheterMVRepairBrand: [
        "Abbott MitraClip",
        "Edwards Pascal",
        "Edwards Cardioband",
        "Carillon",
        "Other",
      ],
      optionsTEERFirstClipPosition: [
        "A1-P1",
        "A2-P2",
        "A3-P3",
        "commissural closure",
      ],
      optionsTEERSecondClipPosition: [
        "A1-P1",
        "A2-P2",
        "A3-P3",
        "commissural closure",
      ],
      optionsTEERThirdClipPosition: [
        "A1-P1",
        "A2-P2",
        "A3-P3",
        "commissural closure",
      ],
      optionsTEEROtherClipPosition: [
        "A1-P1",
        "A2-P2",
        "A3-P3",
        "commissural closure",
      ],
      optionsTMVRAccessSite: ["transapical", "transseptal", "Other"],
      optionsTMVRBioprosthesisBrand: [
        "Tendyne",
        "Tiara",
        "CardiAQ",
        "Intrepid",
        "HighLife",
        "Milipede",
        "Other",
      ],
    };
  },
  computed: {
    ...mapState("patient", ["patient", "role"]),
    readOnly() {
      return this.role == "viewer" || this.role == "";
    },
  },
  watch: {
    patient(v, ov) {
      if (v._id) {
        this.setPatientInfo(v);
      }
    },
  },
  mounted() {
    if (this.patient._id) {
      this.setPatientInfo(this.patient);
    }
  },
  methods: {
    dateMask,
    setPatientInfo(v) {
      this.patientInfo._id = v._id;

      this.patientInfo.intraop_op_mv = v.intraop_op_mv;
      this.patientInfo.intraop_op_mv_surgery_type =
        v.intraop_op_mv_surgery_type;
      this.patientInfo.intraop_op_mv_technique = v.intraop_op_mv_technique;
      this.patientInfo.intraop_op_mv_surgery_technique_mv_repair_annuloplasty =
        v.intraop_op_mv_surgery_technique_mv_repair_annuloplasty;
      this.patientInfo.intraop_op_mv_surgery_technique_mv_repair_triangular_resection =
        v.intraop_op_mv_surgery_technique_mv_repair_triangular_resection;
      this.patientInfo.intraop_op_mv_surgery_technique_mv_repair_quadrangular_resection =
        v.intraop_op_mv_surgery_technique_mv_repair_quadrangular_resection;
      this.patientInfo.intraop_op_mv_surgery_technique_mv_repair_chordae =
        v.intraop_op_mv_surgery_technique_mv_repair_chordae;
      this.patientInfo.intraop_op_mv_others = v.intraop_op_mv_others;
      this.patientInfo.intraop_op_mv_others_explain =
        v.intraop_op_mv_others_explain;
      this.patientInfo.intraop_op_mv_replacement = v.intraop_op_mv_replacement;
      this.patientInfo.intraop_op_mv_surgery_technique_mv_replacement_prosthesis_type =
        v.intraop_op_mv_surgery_technique_mv_replacement_prosthesis_type;
      this.patientInfo.intraop_op_mv_surgery_technique_mv_replacement_prosthesis_size =
        v.intraop_op_mv_surgery_technique_mv_replacement_prosthesis_size;

      this.patientInfo.intraop_mv_mitral_valve_disease =
        v.intraop_mv_mitral_valve_disease;
      this.patientInfo.intraop_mv_etiology_of_secondary_mr =
        v.intraop_mv_etiology_of_secondary_mr;
      this.patientInfo.intraop_mv_pml_normal = v.intraop_mv_pml_normal;
      this.patientInfo.intraop_mv_pml_prolapse = v.intraop_mv_pml_prolapse;
      this.patientInfo.intraop_mv_pml_prolapse_segment =
        v.intraop_mv_pml_prolapse_segment;
      this.patientInfo.intraop_mv_pml_perforation =
        v.intraop_mv_pml_perforation;
      this.patientInfo.intraop_mv_pml_perforation_segment =
        v.intraop_mv_pml_perforation_segment;
      this.patientInfo.intraop_mv_pml_calcification =
        v.intraop_mv_pml_calcification;
      this.patientInfo.intraop_mv_pml_calcification_segment =
        v.intraop_mv_pml_calcification_segment;
      this.patientInfo.intraop_mv_pml_vegetation = v.intraop_mv_pml_vegetation;
      this.patientInfo.intraop_mv_pml_vegetation_segment =
        v.intraop_mv_pml_vegetation_segment;
      this.patientInfo.intraop_mv_pml_tenting = v.intraop_mv_pml_tenting;
      this.patientInfo.intraop_mv_pml_tenting_segment =
        v.intraop_mv_pml_tenting_segment;
      this.patientInfo.intraop_mv_aml_length = v.intraop_mv_aml_length;
      this.patientInfo.intraop_mv_annulus_diameter_antero_posterior =
        v.intraop_mv_annulus_diameter_antero_posterior;

      this.patientInfo.intraop_mv_aml_normal = v.intraop_mv_aml_normal;
      this.patientInfo.intraop_mv_aml_prolapse = v.intraop_mv_aml_prolapse;
      this.patientInfo.intraop_mv_aml_prolapse_segment =
        v.intraop_mv_aml_prolapse_segment;
      this.patientInfo.intraop_mv_aml_perforation =
        v.intraop_mv_aml_perforation;
      this.patientInfo.intraop_mv_aml_perforation_segment =
        v.intraop_mv_aml_perforation_segment;
      this.patientInfo.intraop_mv_aml_calcification =
        v.intraop_mv_aml_calcification;
      this.patientInfo.intraop_mv_aml_calcification_segment =
        v.intraop_mv_aml_calcification_segment;
      this.patientInfo.intraop_mv_aml_vegetation = v.intraop_mv_aml_vegetation;
      this.patientInfo.intraop_mv_aml_vegetation_segment =
        v.intraop_mv_aml_vegetation_segment;
      this.patientInfo.intraop_mv_aml_tenting = v.intraop_mv_aml_tenting;
      this.patientInfo.intraop_mv_aml_tenting_segment =
        v.intraop_mv_aml_tenting_segment;
      this.patientInfo.intraop_mv_type_of_mv_surgery_intervention =
        v.intraop_mv_type_of_mv_surgery_intervention;
      this.patientInfo.intraop_mv_type_of_surgical_mv_repair =
        v.intraop_mv_type_of_surgical_mv_repair;
      this.patientInfo.intraop_mv_other_surgical_mv_repair =
        v.intraop_mv_other_surgical_mv_repair;

      this.patientInfo.intraop_mv_surgical_mv_annuloplasty_type =
        v.intraop_mv_surgical_mv_annuloplasty_type;
      this.patientInfo.intraop_model_of_surgical_mv_annuloplasty_ring_model =
        v.intraop_model_of_surgical_mv_annuloplasty_ring_model;

      this.patientInfo.intraop_size_of_surgical_mv_annuloplasty_ring_size =
        v.intraop_size_of_surgical_mv_annuloplasty_ring_size;

      this.patientInfo.intraop_mv_subannular_mv_repair_type =
        v.intraop_mv_subannular_mv_repair_type;
      this.patientInfo.intraop_mv_pm_repositioning_type =
        v.intraop_mv_pm_repositioning_type;
      this.patientInfo.intraop_mv_other_subannular_mv_repair =
        v.intraop_mv_other_subannular_mv_repair;

      this.patientInfo.intraop_mv_surgical_mv_leaflet_repair_type =
        v.intraop_mv_surgical_mv_leaflet_repair_type;
      this.patientInfo.intraop_mv_surgical_edge_to_edge_repair_location =
        v.intraop_mv_surgical_edge_to_edge_repair_location;
      this.patientInfo.intraop_mv_repair_patch_type =
        v.intraop_mv_repair_patch_type;
      this.patientInfo.intraop_other_patch_materials_in_mv_repair =
        v.intraop_other_patch_materials_in_mv_repair;
      this.patientInfo.intraop_mv_surgical_mv_replacement_type =
        v.intraop_mv_surgical_mv_replacement_type;
      this.patientInfo.intraop_mv_replacement_chordal_sparing =
        v.intraop_mv_replacement_chordal_sparing;
      this.patientInfo.intraop_mv_mechanical_brand =
        v.intraop_mv_mechanical_brand;
      this.patientInfo.intraop_mv_mechanical_prosthesis_brand_other =
        v.intraop_mv_mechanical_prosthesis_brand_other;

      this.patientInfo.intraop_mv_mechanical_prosthesis_size =
        v.intraop_mv_mechanical_prosthesis_size;

      this.patientInfo.intraop_mv_bioprothesis_brand =
        v.intraop_mv_bioprothesis_brand;
      this.patientInfo.intraop_mv_bioprosthesis_brand_other =
        v.intraop_mv_bioprosthesis_brand_other;

      this.patientInfo.intraop_mv_bioprosthesis_size =
        v.intraop_mv_bioprosthesis_size;

      this.patientInfo.intraop_mv_transcatheter_mv_repair_type =
        v.intraop_mv_transcatheter_mv_repair_type;
      this.patientInfo.intraop_mv_transcatheter_mv_repair_other =
        v.intraop_mv_transcatheter_mv_repair_other;

      this.patientInfo.intraop_mv_transcatheter_mv_repair_brand =
        v.intraop_mv_transcatheter_mv_repair_brand;
      this.patientInfo.intraop_mv_transcatheter_mv_repair_brand_other =
        v.intraop_mv_transcatheter_mv_repair_brand_other;
      this.patientInfo.intraop_mv_TEER_size_clip_type =
        v.intraop_mv_TEER_size_clip_type;

      this.patientInfo.intraop_mv_TEER_number_clips =
        v.intraop_mv_TEER_number_clips;

      this.patientInfo.intraop_mv_TEER_first_clip_position =
        v.intraop_mv_TEER_first_clip_position;
      this.patientInfo.intraop_mv_TEER_second_clip_position =
        v.intraop_mv_TEER_second_clip_position;
      this.patientInfo.intraop_mv_TEER_third_clip_position =
        v.intraop_mv_TEER_third_clip_position;
      this.patientInfo.intraop_mv_TEER_other_clip_position =
        v.intraop_mv_TEER_other_clip_position;
      this.patientInfo.intraop_mv_tmvr_access_site =
        v.intraop_mv_tmvr_access_site;
      this.patientInfo.intraop_mv_tmvr_bioprosthesis_brand =
        v.intraop_mv_tmvr_bioprosthesis_brand;
      this.patientInfo.intraop_mv_tmvr_bioprosthesis_brand_other =
        v.intraop_mv_tmvr_bioprosthesis_brand_other;

      this.patientInfo.intraop_mv_tmvr_bioprosthesis_size =
        v.intraop_mv_tmvr_bioprosthesis_size;
    },
  },
};
</script>

<style>
</style>
