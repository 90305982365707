<template>
  <b-card title="TV" sub-title="Information about TV">
    <b-row>
      <b-col md="6">
        <b-row>
          <b-col cols="12">
            <b-card class="bordered-card" title="TV">
              <b-row>
                <b-col cols="12">
                  <b-form-group label="---" label-for="---" label-cols-md="4">
                    <v-select
                      v-model="patientInfo.intraop_op_tv"
                      label="value"
                      :options="options"
                      :disabled="readOnly"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                    label="TV Intervention"
                    label-for="intraop_tv_intervention"
                    label-cols-md="4"
                  >
                    <v-select
                      v-model="patientInfo.intraop_tv_intervention"
                      label="value"
                      :options="optionsTVIntervention"
                      :disabled="readOnly"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                    label="Surgery type"
                    label-for="intraop_op_tv_surgery_type"
                    label-cols-md="4"
                  >
                    <v-select
                      v-model="patientInfo.intraop_op_tv_surgery_type"
                      label="value"
                      :options="options1"
                      :disabled="readOnly"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                    label="Transcatheter TV Repair Type"
                    label-for="transcatheter_tv_repair_type"
                    label-cols-md="4"
                  >
                    <v-select
                      v-model="
                        patientInfo.intraop_tv_transcatheter_tv_repair_type
                      "
                      label="value"
                      :options="optionsTVTranscatheterRepairType"
                      :disabled="readOnly"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                    label="Other Transcatheter TV Repair Type"
                    label-for="intraop_tv_transcatheter_tv_repair_type_other"
                    label-cols-md="4"
                  >
                    <b-form-input
                      id="intraop_tv_transcatheter_tv_repair_type_other"
                      v-model="
                        patientInfo.intraop_tv_transcatheter_tv_repair_type_other
                      "
                      placeholder="Other Transcatheter TV Repair Type"
                      :readonly="readOnly"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card>
            <b-card class="bordered-card" title="Disease">
              <b-col cols="12">
                <b-form-group
                  label="Tricuspid Valve Disease"
                  label-for="tricuspid_valve_disease"
                  label-cols-md="4"
                >
                  <v-select
                    v-model="patientInfo.intraop_tv_tricuspid_valve_disease"
                    label="value"
                    :options="optionsTricuspidValveDisease"
                    :disabled="readOnly"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="Tricuspid Valve Normal"
                  label-for="tv_normal"
                  label-cols-md="4"
                >
                  <v-select
                    v-model="patientInfo.intraop_tv_normal"
                    label="value"
                    :options="options"
                    :disabled="readOnly"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="Tricuspid Valve Prolapse"
                  label-for="tv_prolapse"
                  label-cols-md="4"
                >
                  <v-select
                    v-model="patientInfo.intraop_tv_prolapse"
                    label="value"
                    :options="options"
                    :disabled="readOnly"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="Tricuspid Valve Prolapse Leaflet"
                  label-for="tv_prolapse_leaflet"
                  label-cols-md="4"
                >
                  <v-select
                    v-model="patientInfo.intraop_tv_prolapse_leaflet"
                    label="value"
                    :options="optionsTVProlapseLeaflet"
                    :disabled="readOnly"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="Tricuspid Valve Perforation"
                  label-for="tv_perforation"
                  label-cols-md="4"
                >
                  <v-select
                    v-model="patientInfo.intraop_tv_perforation"
                    label="value"
                    :options="options"
                    :disabled="readOnly"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="Tricuspid Valve Perforation Leaflet"
                  label-for="tv_perforation_leaflet"
                  label-cols-md="4"
                >
                  <v-select
                    v-model="patientInfo.intraop_tv_perforation_leaflet"
                    label="value"
                    :options="optionsTVPerforationLeaflet"
                    :disabled="readOnly"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="Tricuspid Valve Calcification"
                  label-for="tv_calcification"
                  label-cols-md="4"
                >
                  <v-select
                    v-model="patientInfo.intraop_tv_calcification"
                    label="value"
                    :options="options"
                    :disabled="readOnly"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="Tricuspid Valve Calcification Leaflet"
                  label-for="tv_calcification_leaflet"
                  label-cols-md="4"
                >
                  <v-select
                    v-model="patientInfo.intraop_tv_calcification_leaflet"
                    label="value"
                    :options="optionsTVCalcificationLeaflet"
                    :disabled="readOnly"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="Tricuspid Valve Vegetation"
                  label-for="tv_vegetation"
                  label-cols-md="4"
                >
                  <v-select
                    v-model="patientInfo.intraop_tv_vegetation"
                    label="value"
                    :options="options"
                    :disabled="readOnly"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="Tricuspid Valve Vegetation Leaflet"
                  label-for="tv_vegetation_leaflet"
                  label-cols-md="4"
                >
                  <v-select
                    v-model="patientInfo.intraop_tv_vegetation_leaflet"
                    label="value"
                    :options="optionsTVVegetationLeaflet"
                    :disabled="readOnly"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="Tricuspid Valve Retraction"
                  label-for="tv_retraction"
                  label-cols-md="4"
                >
                  <v-select
                    v-model="patientInfo.intraop_tv_retraction"
                    label="value"
                    :options="options"
                    :disabled="readOnly"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="Tricuspid Valve Retraction Leaflet"
                  label-for="tv_retraction_leaflet"
                  label-cols-md="4"
                >
                  <v-select
                    v-model="patientInfo.intraop_tv_retraction_leaflet"
                    label="value"
                    :options="optionsTVRetractionLeaflet"
                    :disabled="readOnly"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="Tricuspid Valve Retraction PM Wires"
                  label-for="tv_retraction_pm_wires"
                  label-cols-md="4"
                >
                  <v-select
                    v-model="patientInfo.intraop_tv_retraction_pm_wires"
                    label="value"
                    :options="options"
                    :disabled="readOnly"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="Tricuspid Valve Tethering"
                  label-for="tv_tethering"
                  label-cols-md="4"
                >
                  <v-select
                    v-model="patientInfo.intraop_tv_tethering"
                    label="value"
                    :options="options"
                    :disabled="readOnly"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="Tricuspid Valve Tethering Leaflet"
                  label-for="tv_tethering_leaflet"
                  label-cols-md="4"
                >
                  <v-select
                    v-model="patientInfo.intraop_tv_tethering_leaflet"
                    label="value"
                    :options="optionsTVTetheringLeaflet"
                    :disabled="readOnly"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="TV Annulus Max Diameter (Septal-Lateral and Antero-Posterior) [mm]"
                  label-for="tv_annulus_max_diameter"
                  label-cols-md="4"
                >
                  <b-form-input
                    v-model="
                      patientInfo.intraop_tv_annulus_max_diameter_septal_lateral_and_antero_posterior
                    "
                    id="tv_annulus_max_diameter"
                    type="number"
                    placeholder="TV Annulus Max Diameter"
                    :readonly="readOnly"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="Comments for Cusp Analysis"
                  label-for="intraop_tv_comments_for_cusp_analysis"
                  label-cols-md="4"
                >
                  <b-form-input
                    id="intraop_tv_comments_for_cusp_analysis"
                    v-model="patientInfo.intraop_tv_comments_for_cusp_analysis"
                    placeholder="Comments for Cusp Analysis"
                    :readonly="readOnly"
                  />
                </b-form-group>
              </b-col>

              <b-col cols="12">
                <b-form-group
                  label="Intention to Repair After Cusp Analysis"
                  label-for="tv_intention_to_repair_after_cusp_analysis"
                  label-cols-md="4"
                >
                  <v-select
                    v-model="
                      patientInfo.intraop_tv_intention_to_repair_after_cusp_analysis
                    "
                    label="value"
                    :options="options"
                    :disabled="readOnly"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="Reason for No Intention to Repair"
                  label-for="intraop_tv_reason_for_no_intention_to_repair"
                  label-cols-md="4"
                >
                  <b-form-input
                    id="intraop_tv_reason_for_no_intention_to_repair"
                    v-model="
                      patientInfo.intraop_tv_reason_for_no_intention_to_repair
                    "
                    placeholder="Reason for No Intention to Repair"
                    :readonly="readOnly"
                  />
                </b-form-group>
              </b-col>
            </b-card>
          </b-col>
          <b-col cols="12">
            <b-card class="bordered-card" title="Repair">
              <b-row>
                <b-col cols="12">
                  <b-form-group
                    label="Repair annuloplasty"
                    label-for="intraop_op_tv_repair_annuloplasty"
                    label-cols-md="4"
                  >
                    <v-select
                      v-model="patientInfo.intraop_op_tv_repair_annuloplasty"
                      label="value"
                      :options="options"
                      :disabled="readOnly"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                    label="Repair chordae"
                    label-for="intraop_op_tv_repair_chordae"
                    label-cols-md="4"
                  >
                    <v-select
                      v-model="patientInfo.intraop_op_tv_repair_chordae"
                      label="value"
                      :options="options"
                      :disabled="readOnly"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                    label="Surgical TV Repair Type"
                    label-for="surgical_tv_repair_type"
                    label-cols-md="4"
                  >
                    <v-select
                      v-model="patientInfo.intraop_tv_surgical_tv_repair_type"
                      label="value"
                      :options="optionsTVRepairType"
                      :disabled="readOnly"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                    label="Surgical TV Annuloplasty Ring Model"
                    label-for="intraop_tv_surgical_tv_annuloplasty_ring_model"
                    label-cols-md="4"
                  >
                    <b-form-input
                      id="intraop_tv_surgical_tv_annuloplasty_ring_model"
                      v-model="
                        patientInfo.intraop_tv_surgical_tv_annuloplasty_ring_model
                      "
                      placeholder="Surgical TV Annuloplasty Ring Model"
                      :readonly="readOnly"
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="12">
                  <b-form-group
                    label="Surgical TV Annuloplasty Ring Size [mm]"
                    label-for="surgical_tv_annuloplasty_ring_size"
                    label-cols-md="4"
                  >
                    <b-form-input
                      v-model="
                        patientInfo.intraop_tv_surgical_tv_annuloplasty_ring_size
                      "
                      id="surgical_tv_annuloplasty_ring_size"
                      type="number"
                      placeholder="Surgical TV Annuloplasty Ring Size"
                      :readonly="readOnly"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                    label="Other Surgical TV Repair"
                    label-for="intraop_tv_surgical_tv_repair_other"
                    label-cols-md="4"
                  >
                    <b-form-input
                      id="intraop_tv_surgical_tv_repair_other"
                      v-model="patientInfo.intraop_tv_surgical_tv_repair_other"
                      placeholder="Other Surgical TV Repair"
                      :readonly="readOnly"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
      <b-col md="6">
        <b-row>
          <b-col cols="12">
            <b-card class="bordered-card" title="Other">
              <b-row>
                <b-col cols="12">
                  <b-form-group label="---" label-for="---" label-cols-md="4">
                    <v-select
                      v-model="patientInfo.intraop_op_tv_others"
                      label="value"
                      :options="options"
                      :disabled="readOnly"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                    label="Explain"
                    label-for="mitraclip"
                    label-cols-md="4"
                  >
                    <b-form-textarea
                      v-model="patientInfo.intraop_op_tv_others_explain"
                      rows="3"
                      placeholder="Explain"
                      :readonly="readOnly"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
          <b-col cols="12">
            <b-card class="bordered-card" title="Replacement">
              <b-row>
                <b-col cols="12">
                  <b-form-group
                    label="Prosthesis type"
                    label-for="intraop_op_tv_replacement_prosthesis_type"
                    label-cols-md="4"
                  >
                    <b-form-input
                      v-model="
                        patientInfo.intraop_op_tv_replacement_prosthesis_type
                      "
                      id="intraop_op_tv_replacement_prosthesis_type"
                      placeholder="Prosthesis type"
                      :readonly="readOnly"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                    label="Prosthesis size"
                    label-for="intraop_op_tv_replacement_prosthesis_size"
                    label-cols-md="4"
                  >
                    <b-form-input
                      v-model="
                        patientInfo.intraop_op_tv_replacement_prosthesis_size
                      "
                      id="intraop_op_tv_replacement_prosthesis_type"
                      type="number"
                      placeholder="Prosthesis size"
                      :readonly="readOnly"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                    label="Surgical TV Replacement Type"
                    label-for="surgical_tv_replacement_type"
                    label-cols-md="4"
                  >
                    <v-select
                      v-model="
                        patientInfo.intraop_tv_surgical_tv_replacement_type
                      "
                      label="value"
                      :options="optionsTVReplacementType"
                      :disabled="readOnly"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                    label="Mechanical Prosthesis Brand"
                    label-for="surgical_tv_replacement_mechanical_brand"
                    label-cols-md="4"
                  >
                    <v-select
                      v-model="
                        patientInfo.intraop_tv_surgical_tv_replacement_mechanical_brand
                      "
                      label="value"
                      :options="optionsTVReplacementMechanicalBrand"
                      :disabled="readOnly"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                    label="Other Mechanical TV Replacement Brand"
                    label-for="intraop_tv_surgical_tv_replacement_mechanical_brand_other"
                    label-cols-md="4"
                  >
                    <b-form-input
                      id="intraop_tv_surgical_tv_replacement_mechanical_brand_other"
                      v-model="
                        patientInfo.intraop_tv_surgical_tv_replacement_mechanical_brand_other
                      "
                      placeholder="Other Mechanical TV Replacement Brand"
                      :readonly="readOnly"
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="12">
                  <b-form-group
                    label="Surgical TV Replacement Mechanical Prosthesis Size"
                    label-for="surgical_tv_replacement_mechanical_prosthesis_size"
                    label-cols-md="4"
                  >
                    <b-form-input
                      v-model="
                        patientInfo.intraop_tv_surgical_tv_replacement_mechanical_prosthesis_size
                      "
                      id="surgical_tv_replacement_mechanical_prosthesis_size"
                      type="number"
                      placeholder="Surgical TV Replacement Mechanical Prosthesis Size"
                      :readonly="readOnly"
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="12">
                  <b-form-group
                    label="Bioprosthesis Brand"
                    label-for="surgical_tv_replacement_bioprothesis_brand"
                    label-cols-md="4"
                  >
                    <v-select
                      v-model="
                        patientInfo.intraop_tv_surgical_tv_replacement_bioprothesis_brand
                      "
                      label="value"
                      :options="optionsTVReplacementBioprothesisBrand"
                      :disabled="readOnly"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                    label="Other Bioprosthesis TV Replacement Brand"
                    label-for="intraop_tv_surgical_tv_replacement_bioprosthesis_brand_other"
                    label-cols-md="4"
                  >
                    <b-form-input
                      id="intraop_tv_surgical_tv_replacement_bioprosthesis_brand_other"
                      v-model="
                        patientInfo.intraop_tv_surgical_tv_replacement_bioprosthesis_brand_other
                      "
                      placeholder="Other Bioprosthesis TV Replacement Brand"
                      :readonly="readOnly"
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="12">
                  <b-form-group
                    label="Surgical TV Replacement Bioprosthesis Size [mm]"
                    label-for="surgical_tv_replacement_bioprosthesis_size"
                    label-cols-md="4"
                  >
                    <b-form-input
                      v-model="
                        patientInfo.intraop_tv_surgical_tv_replacement_bioprosthesis_size
                      "
                      id="surgical_tv_replacement_bioprosthesis_size"
                      type="number"
                      placeholder="Surgical TV Replacement Bioprosthesis Size"
                      :readonly="readOnly"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card>
            <b-card
              class="bordered-card"
              title="Transcatheter Edge-to-Edge Repair (TEER)"
            >
              <b-col cols="12">
                <b-form-group
                  label="TEER Size Clip Type"
                  label-for="intraop_tv_TEER_size_clip_type"
                  label-cols-md="4"
                >
                  <b-form-input
                    id="intraop_tv_TEER_size_clip_type"
                    v-model="patientInfo.intraop_tv_TEER_size_clip_type"
                    placeholder="TEER Size Clip Type"
                    :readonly="readOnly"
                  />
                </b-form-group>
              </b-col>

              <b-col cols="12">
                <b-form-group
                  label="TV TEER Number of Clips"
                  label-for="tv_teer_number_of_clips"
                  label-cols-md="4"
                >
                  <b-form-input
                    v-model="patientInfo.intraop_tv_TEER_number_of_clips"
                    id="tv_teer_number_of_clips"
                    type="number"
                    placeholder="TV TEER Number of Clips"
                    :readonly="readOnly"
                  />
                </b-form-group>
              </b-col>

              <b-col cols="12">
                <b-form-group
                  label="TEER First Clip Position"
                  label-for="TEER_first_clip_position"
                  label-cols-md="4"
                >
                  <v-select
                    v-model="patientInfo.intraop_tv_TEER_first_clip_position"
                    label="value"
                    :options="optionsTVTEERFirstClipPosition"
                    :disabled="readOnly"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="TEER Second Clip Position"
                  label-for="TEER_second_clip_position"
                  label-cols-md="4"
                >
                  <v-select
                    v-model="patientInfo.intraop_tv_TEER_second_clip_position"
                    label="value"
                    :options="optionsTVTEERSecondClipPosition"
                    :disabled="readOnly"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="TEER Third Clip Position"
                  label-for="TEER_third_clip_position"
                  label-cols-md="4"
                >
                  <v-select
                    v-model="patientInfo.intraop_tv_TEER_third_clip_position"
                    label="value"
                    :options="optionsTVTEERThirdClipPosition"
                    :disabled="readOnly"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="TEER Other Clip Position"
                  label-for="TEER_other_clip_position"
                  label-cols-md="4"
                >
                  <v-select
                    v-model="patientInfo.intraop_tv_TEER_other_clip_position"
                    label="value"
                    :options="optionsTVTEEROtherClipPosition"
                    :disabled="readOnly"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="TEER Clover"
                  label-for="TEER_clover"
                  label-cols-md="4"
                >
                  <v-select
                    v-model="patientInfo.intraop_tv_TEER_clover"
                    label="value"
                    :options="options"
                    :disabled="readOnly"
                  />
                </b-form-group>
              </b-col>
            </b-card>
            <b-card
              class="bordered-card"
              title="Transcatheter Tricuspid Valve Repair (TTVR)"
            >
              <b-col cols="12">
                <b-form-group
                  label="TTVR Brand"
                  label-for="transcatheter_tv_repair_brand"
                  label-cols-md="4"
                >
                  <v-select
                    v-model="
                      patientInfo.intraop_tv_transcatheter_tv_repair_brand
                    "
                    label="value"
                    :options="optionsTVTranscatheterRepairBrand"
                    :disabled="readOnly"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="Other TTVR Brand"
                  label-for="intraop_tv_transcatheter_tv_repair_brand_other"
                  label-cols-md="4"
                >
                  <b-form-input
                    id="intraop_tv_transcatheter_tv_repair_brand_other"
                    v-model="
                      patientInfo.intraop_tv_transcatheter_tv_repair_brand_other
                    "
                    placeholder="Other Transcatheter TV Repair Brand"
                    :readonly="readOnly"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="TTVR Access Site"
                  label-for="ttvr_access_site"
                  label-cols-md="4"
                >
                  <v-select
                    v-model="patientInfo.intraop_tv_ttvr_access_site"
                    label="value"
                    :options="optionsTTVRAccessSite"
                    :disabled="readOnly"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="TTVR Bioprosthesis Brand"
                  label-for="intraop_tv_ttvr_bioprosthesis_brand"
                  label-cols-md="4"
                >
                  <b-form-input
                    id="intraop_tv_ttvr_bioprosthesis_brand"
                    v-model="patientInfo.intraop_tv_ttvr_bioprosthesis_brand"
                    placeholder="TTVR Bioprosthesis Brand"
                    :readonly="readOnly"
                  />
                </b-form-group>
              </b-col>

              <b-col cols="12">
                <b-form-group
                  label="TV TTVR Bioprosthesis Size [mm]"
                  label-for="tv_ttvr_bioprosthesis_size"
                  label-cols-md="4"
                >
                  <b-form-input
                    v-model="patientInfo.intraop_tv_ttvr_bioprosthesis_size"
                    id="tv_ttvr_bioprosthesis_size"
                    type="number"
                    placeholder="TV TTVR Bioprosthesis Size"
                    :readonly="readOnly"
                  />
                </b-form-group>
              </b-col>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BFormDatepicker,
  BFormTextarea,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { dateMask } from "@core/utils/utils";
import { mapState } from "vuex";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BFormDatepicker,
    BFormTextarea,
    vSelect,
  },
  data() {
    return {
      patientInfo: {
        _id: null,
        intraop_op_tv: "",
        intraop_op_tv_surgery_type: "",
        intraop_op_tv_repair_annuloplasty: "",
        intraop_op_tv_repair_chordae: "",
        intraop_op_tv_others: "",
        intraop_op_tv_others_explain: "",
        intraop_op_tv_replacement_prosthesis_type: "",
        intraop_op_tv_replacement_prosthesis_size: 0,
        intraop_tv_tricuspid_valve_disease: "",
        intraop_tv_normal: "",
        intraop_tv_prolapse: "",
        intraop_tv_prolapse_leaflet: "",
        intraop_tv_perforation: "",
        intraop_tv_perforation_leaflet: "",
        intraop_tv_calcification: "",
        intraop_tv_calcification_leaflet: "",
        intraop_tv_vegetation: "",
        intraop_tv_vegetation_leaflet: "",
        intraop_tv_retraction: "",
        intraop_tv_retraction_leaflet: "",
        intraop_tv_retraction_pm_wires: "",
        intraop_tv_tethering: "",
        intraop_tv_tethering_leaflet: "",
        intraop_tv_annulus_max_diameter_septal_lateral_and_antero_posterior: "",
        intraop_tv_comments_for_cusp_analysis: "",

        intraop_tv_intention_to_repair_after_cusp_analysis: "",
        intraop_tv_reason_for_no_intention_to_repair: "",

        intraop_tv_intervention: "",
        intraop_tv_surgical_tv_repair_type: "",
        intraop_tv_surgical_tv_annuloplasty_ring_model: "",

        intraop_tv_surgical_tv_annuloplasty_ring_size: "",
        intraop_tv_surgical_tv_repair_other: "",

        intraop_tv_surgical_tv_replacement_type: "",
        intraop_tv_surgical_tv_replacement_mechanical_brand: "",
        intraop_tv_surgical_tv_replacement_mechanical_brand_other: "",

        intraop_tv_surgical_tv_replacement_mechanical_prosthesis_size: "",

        intraop_tv_surgical_tv_replacement_bioprothesis_brand: "",
        intraop_tv_surgical_tv_replacement_bioprosthesis_brand_other: "",

        intraop_tv_surgical_tv_replacement_bioprosthesis_size: "",

        intraop_tv_transcatheter_tv_repair_type: "",
        intraop_tv_transcatheter_tv_repair_type_other: "",

        intraop_tv_transcatheter_tv_repair_brand: "",
        intraop_tv_transcatheter_tv_repair_brand_other: "",
        intraop_tv_TEER_size_clip_type: "",

        intraop_tv_TEER_number_of_clips: "",

        intraop_tv_TEER_first_clip_position: "",
        intraop_tv_TEER_second_clip_position: "",
        intraop_tv_TEER_third_clip_position: "",
        intraop_tv_TEER_other_clip_position: "",
        intraop_tv_TEER_clover: "",
        intraop_tv_ttvr_access_site: "",
        intraop_tv_ttvr_bioprosthesis_brand: "",

        intraop_tv_ttvr_bioprosthesis_size: "",
      },
      options: ["yes", "no"],
      options1: ["TVR open", "TVR MIC", "TV replace open", "TV replace MIC"],
      optionsTricuspidValveDisease: [
        "Secondary",
        "Degenerative",
        "Mixed",
        "Rheumatic",
        "Endocarditis",
        "Trauma",
        "Congenital",
        "Papillary muscle rupture",
        "PM-related damage",
        "Other",
      ],
      optionsTVProlapseLeaflet: ["anterior", "posterior", "septal"],
      optionsTVPerforationLeaflet: ["anterior", "posterior", "septal"],
      optionsTVCalcificationLeaflet: ["anterior", "posterior", "septal"],
      optionsTVVegetationLeaflet: ["anterior", "posterior", "septal"],
      optionsTVRetractionLeaflet: ["anterior", "posterior", "septal"],
      optionsTVTetheringLeaflet: ["anterior", "posterior", "septal"],
      optionsTVIntervention: [
        "Surgical TV repair",
        "Surgical TV replacement",
        "Transcatheter TV repair",
        "Transcatheter TV replacement",
        "None",
      ],
      optionsTVRepairType: [
        "Ring annuloplasty",
        "Suture Annuloplasty (De Vega)",
        "Suture Annuloplasty (Kay)",
        "Isolated leaflet repair",
        "Annuloplasty + leaflet repair",
        "Other",
      ],
      optionsTVReplacementType: [
        "Mechanical prosthesis",
        "Biological prosthesis",
      ],
      optionsTVReplacementMechanicalBrand: [
        "Abbott SJM",
        "Livanova Carbomedics",
        "Medtronic Hall",
        "Cryolife ON-X",
        "Other",
      ],
      optionsTVReplacementBioprothesisBrand: [
        "CE Perimount",
        "CE Magna/Magna Ease",
        "Medtronic Hancock",
        "Medtronic Mosaic",
        "St Jude Abbott Epic",
        "Other",
      ],
      optionsTVTranscatheterRepairType: [
        "Edge-to-Edge",
        "Annuloplasty",
        "Other",
      ],
      optionsTVTranscatheterRepairBrand: [
        "Abbott TriClip",
        "Abbott MitraClip",
        "Edwards Pascal",
        "Edwards Cardioband",
        "Other",
      ],
      optionsTVTEERFirstClipPosition: [
        "Antero-septal",
        "Postero-septal",
        "Antero-postero",
      ],
      optionsTVTEERSecondClipPosition: [
        "Antero-septal",
        "Postero-septal",
        "Antero-postero",
      ],
      optionsTVTEERThirdClipPosition: [
        "Antero-septal",
        "Postero-septal",
        "Antero-postero",
      ],
      optionsTVTEEROtherClipPosition: [
        "Antero-septal",
        "Postero-septal",
        "Antero-postero",
      ],
      optionsTTVRAccessSite: ["Transapical", "Femoral", "Jugular", "Other"],
    };
  },
  computed: {
    ...mapState("patient", ["patient", "role"]),
    readOnly() {
      return this.role == "viewer" || this.role == "";
    },
  },
  watch: {
    patient(v, ov) {
      if (v._id) {
        this.setPatientInfo(v);
      }
    },
  },
  mounted() {
    if (this.patient._id) {
      this.setPatientInfo(this.patient);
    }
  },
  methods: {
    dateMask,
    setPatientInfo(v) {
      this.patientInfo._id = v._id;

      this.patientInfo.intraop_op_tv = v.intraop_op_tv;
      this.patientInfo.intraop_op_tv_surgery_type =
        v.intraop_op_tv_surgery_type;
      this.patientInfo.intraop_op_tv_repair_annuloplasty =
        v.intraop_op_tv_repair_annuloplasty;
      this.patientInfo.intraop_op_tv_repair_chordae =
        v.intraop_op_tv_repair_chordae;
      this.patientInfo.intraop_op_tv_others = v.intraop_op_tv_others;
      this.patientInfo.intraop_op_tv_others_explain =
        v.intraop_op_tv_others_explain;
      this.patientInfo.intraop_op_tv_replacement_prosthesis_type =
        v.intraop_op_tv_replacement_prosthesis_type;
      this.patientInfo.intraop_op_tv_replacement_prosthesis_size =
        v.intraop_op_tv_replacement_prosthesis_size;
      this.patientInfo.intraop_tv_tricuspid_valve_disease =
        v.intraop_tv_tricuspid_valve_disease;
      this.patientInfo.intraop_tv_normal = v.intraop_tv_normal;
      this.patientInfo.intraop_tv_prolapse = v.intraop_tv_prolapse;
      this.patientInfo.intraop_tv_prolapse_leaflet =
        v.intraop_tv_prolapse_leaflet;
      this.patientInfo.intraop_tv_perforation = v.intraop_tv_perforation;
      this.patientInfo.intraop_tv_perforation_leaflet =
        v.intraop_tv_perforation_leaflet;
      this.patientInfo.intraop_tv_calcification = v.intraop_tv_calcification;
      this.patientInfo.intraop_tv_calcification_leaflet =
        v.intraop_tv_calcification_leaflet;
      this.patientInfo.intraop_tv_vegetation = v.intraop_tv_vegetation;
      this.patientInfo.intraop_tv_vegetation_leaflet =
        v.intraop_tv_vegetation_leaflet;
      this.patientInfo.intraop_tv_retraction = v.intraop_tv_retraction;
      this.patientInfo.intraop_tv_retraction_leaflet =
        v.intraop_tv_retraction_leaflet;
      this.patientInfo.intraop_tv_retraction_pm_wires =
        v.intraop_tv_retraction_pm_wires;
      this.patientInfo.intraop_tv_tethering = v.intraop_tv_tethering;
      this.patientInfo.intraop_tv_tethering_leaflet =
        v.intraop_tv_tethering_leaflet;
      this.patientInfo.intraop_tv_annulus_max_diameter_septal_lateral_and_antero_posterior =
        v.intraop_tv_annulus_max_diameter_septal_lateral_and_antero_posterior;
      this.patientInfo.intraop_tv_comments_for_cusp_analysis =
        v.intraop_tv_comments_for_cusp_analysis;

      this.patientInfo.intraop_tv_intention_to_repair_after_cusp_analysis =
        v.intraop_tv_intention_to_repair_after_cusp_analysis;
      this.patientInfo.intraop_tv_reason_for_no_intention_to_repair =
        v.intraop_tv_reason_for_no_intention_to_repair;

      this.patientInfo.intraop_tv_intervention = v.intraop_tv_intervention;
      this.patientInfo.intraop_tv_surgical_tv_repair_type =
        v.intraop_tv_surgical_tv_repair_type;
      this.patientInfo.intraop_tv_surgical_tv_annuloplasty_ring_model =
        v.intraop_tv_surgical_tv_annuloplasty_ring_model;

      this.patientInfo.intraop_tv_surgical_tv_annuloplasty_ring_size =
        v.intraop_tv_surgical_tv_annuloplasty_ring_size;
      this.patientInfo.intraop_tv_surgical_tv_repair_other =
        v.intraop_tv_surgical_tv_repair_other;

      this.patientInfo.intraop_tv_surgical_tv_replacement_type =
        v.intraop_tv_surgical_tv_replacement_type;
      this.patientInfo.intraop_tv_surgical_tv_replacement_mechanical_brand =
        v.intraop_tv_surgical_tv_replacement_mechanical_brand;
      this.patientInfo.intraop_tv_surgical_tv_replacement_mechanical_brand_other =
        v.intraop_tv_surgical_tv_replacement_mechanical_brand_other;

      this.patientInfo.intraop_tv_surgical_tv_replacement_mechanical_prosthesis_size =
        v.intraop_tv_surgical_tv_replacement_mechanical_prosthesis_size;

      this.patientInfo.intraop_tv_surgical_tv_replacement_bioprothesis_brand =
        v.intraop_tv_surgical_tv_replacement_bioprothesis_brand;
      this.patientInfo.intraop_tv_surgical_tv_replacement_bioprosthesis_brand_other =
        v.intraop_tv_surgical_tv_replacement_bioprosthesis_brand_other;

      this.patientInfo.intraop_tv_surgical_tv_replacement_bioprosthesis_size =
        v.intraop_tv_surgical_tv_replacement_bioprosthesis_size;

      this.patientInfo.intraop_tv_transcatheter_tv_repair_type =
        v.intraop_tv_transcatheter_tv_repair_type;
      this.patientInfo.intraop_tv_transcatheter_tv_repair_type_other =
        v.intraop_tv_transcatheter_tv_repair_type_other;

      this.patientInfo.intraop_tv_transcatheter_tv_repair_brand =
        v.intraop_tv_transcatheter_tv_repair_brand;
      this.patientInfo.intraop_tv_transcatheter_tv_repair_brand_other =
        v.intraop_tv_transcatheter_tv_repair_brand_other;
      this.patientInfo.intraop_tv_TEER_size_clip_type =
        v.intraop_tv_TEER_size_clip_type;

      this.patientInfo.intraop_tv_TEER_number_of_clips =
        v.intraop_tv_TEER_number_of_clips;

      this.patientInfo.intraop_tv_TEER_first_clip_position =
        v.intraop_tv_TEER_first_clip_position;
      this.patientInfo.intraop_tv_TEER_second_clip_position =
        v.intraop_tv_TEER_second_clip_position;
      this.patientInfo.intraop_tv_TEER_third_clip_position =
        v.intraop_tv_TEER_third_clip_position;
      this.patientInfo.intraop_tv_TEER_other_clip_position =
        v.intraop_tv_TEER_other_clip_position;
      this.patientInfo.intraop_tv_TEER_clover = v.intraop_tv_TEER_clover;
      this.patientInfo.intraop_tv_ttvr_access_site =
        v.intraop_tv_ttvr_access_site;
      this.patientInfo.intraop_tv_ttvr_bioprosthesis_brand =
        v.intraop_tv_ttvr_bioprosthesis_brand;

      this.patientInfo.intraop_tv_ttvr_bioprosthesis_size =
        v.intraop_tv_ttvr_bioprosthesis_size;
    },
  },
};
</script>

<style>
</style>
