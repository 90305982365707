<template>
  <b-row class="match-height intra-tab">
    <b-col md="12">
      <operation ref="operation"></operation>
    </b-col>
    <b-col md="6">
      <ecc ref="ecc"></ecc>
    </b-col>
    <b-col md="12">
      <tv ref="tv"></tv>
    </b-col>
    <b-col md="12">
      <mv ref="mv"></mv>
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol, BCard } from "bootstrap-vue";
import Operation from "./intraop/Operation";
import Tv from "./intraop/TV";
import Mv from "./intraop/MV";
import Ecc from "./intraop/ECC";
import { mapState, mapActions } from "vuex";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import config from "@/config";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    Tv,
    Mv,
    Ecc,
    Operation,
  },
  timers: { updatePatient: config.TIMER_OPTION },
  computed: {
    ...mapState("patient", ["patient", "role"]),
    readOnly() {
      return this.role == "viewer" || this.role == "";
    },
  },
  methods: {
    ...mapActions("patient", ["updatePatientById"]),

    updatePatient() {
      if (this.readOnly) {
        return;
      }

      let activeElement = document.activeElement;
      let blockingElements = ["input", "textarea"];
      let isFlatPickrInput =
        activeElement && activeElement.classList.contains("flatpickr-input");

      // check whether an input field is the active elemnent,
      //if it is, do not interrupt the users entries with updating
      // the patientInfo but return instead
      if (
        activeElement &&
        blockingElements.indexOf(activeElement.tagName.toLowerCase()) !== -1 &&
        !isFlatPickrInput // still save, if the active element is a flatpickr element and thus a date field
      ) {
        return;
      }

      let patientInfo = {
        ...this.$refs.operation.patientInfo,
        ...this.$refs.tv.patientInfo,
        ...this.$refs.mv.patientInfo,
        ...this.$refs.ecc.patientInfo,
      };

      this.updatePatientById(patientInfo).catch((err) => {
        this.showToast(
          "danger",
          "Error",
          "An error occured while updating the patient."
        );
      });
    },
    showToast(variant, title, content) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: title,
          icon: "BellIcon",
          text: content,
          variant,
        },
      });
    },
  },
};
</script>

<style lang="scss">
.intra-tab {
  .vertical-align {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }
  .bordered-card {
    border: 1px solid rgba(34, 41, 47, 0.125);
  }
}
</style>
